import React, {Suspense, useEffect} from 'react';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {useNavigate} from 'react-router-dom';

import ContentsHeader from '@/layouts/ContentsHeader';
import Loader from '@/components/common/Loader';
import Layout from '@/layouts';

import styles from './styles.module.scss';
import List from './LIst';

export default function PushList() {
  const navigator = useNavigate();
  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  }, []);
  return (
    <Layout.Main>
      <div className={`${styles.wrapper}`}>
        <ContentsHeader
          txt="알림"
          isOption="none"
          back={() => navigator('/user')}
        />
        <Suspense fallback={<Loader />}>
          <List />
        </Suspense>
      </div>
    </Layout.Main>
  );
}
