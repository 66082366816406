import React, {useEffect, useRef, useState} from 'react';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Keyboard, KeyboardResize} from '@capacitor/keyboard';
import {useRecoilValue} from 'recoil';

import Layout from '@/layouts';
import {useGetCourseBanners, useGetCourseList} from '@/hooks/queries/brands';
import OverlayHeader from '@/layouts/OverlayHeader';
import BrandShareBottomSheet from '@/components/brandShareBottomSheet';
import {UserInfo} from '@/store';

import CourseList from './components/CourseList';
import LoginRequest from './components/LoginRequest';

export default function Search() {
  const offsetTop = useRef(0);
  const userInfo = useRecoilValue(UserInfo);
  const {data: courseBanners} = useGetCourseBanners();
  const {data: courseList} = useGetCourseList();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpenShareSheet, setIsOpenShareSheet] = useState(false);
  const [hasLocked, setHasLocked] = useState(false);
  const onScroll = () => {
    if (window.scrollY > 10) {
      setIsScrolled(() => true);
    } else {
      setIsScrolled(() => false);
    }

    if (window.scrollY > 1000 && !hasLocked && userInfo.id === -1) {
      setHasLocked(() => true);
      document.body.style.overflow = 'hidden';
    }
  };
  // const isDoingSearch = useMemo(() => {
  //   return search.length > 0;
  // }, [search]);
  // const sender = useLogger();

  // const doSearch = () => {
  //   setIsSearching(true);
  //   getSearchedBrands();
  //   Keyboard.hide();
  // };

  // const initSearch = () => {
  //   setSearch('');
  //   setIsSearching(false);
  // };

  // const getSearchedBrands = async () => {
  //   const res = await getBrandSearch(token, search);
  //   sender({
  //     _msg: 'search_click',
  //     _screen: window.location.pathname,
  //     _action: Action.CLICK,
  //     _keyword: search,
  //   });
  //   setSearchedBrandList(res.data);
  // };

  // const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearch($event.target.value);
  // };

  // const activeEnter = ($event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if ($event.key === 'Enter') {
  //     doSearch();
  //   }
  // };

  const initKeyboard = () => {
    Keyboard.addListener('keyboardWillShow', info => {
      console.log('keyboard will show with height:', info.keyboardHeight);
      const mode: KeyboardResize = KeyboardResize.Body; // 타입 정의에 맞는 값 설정
      Keyboard.setResizeMode({
        mode: mode,
      });
    });

    Keyboard.addListener('keyboardDidShow', info => {
      window.scrollTo(0, offsetTop.current + info.keyboardHeight);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      console.log('keyboard will hide');
    });

    Keyboard.addListener('keyboardDidHide', () => {
      console.log('keyboard did hide');
    });
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
      initKeyboard();
    }
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Layout.Main>
      <LoginRequest hasLocked={hasLocked} />
      <div>
        <OverlayHeader
          isScrolled={isScrolled}
          clickShare={() => setIsOpenShareSheet(true)}
          noBack
        />
        <div>
          {courseBanners && courseBanners.length > 0 && (
            <div>
              <img src={courseBanners[0].image} alt="course" />
            </div>
          )}
          <CourseList itemList={courseList || []} />
          {courseBanners && courseBanners.length > 1 && (
            <div>
              <img
                src={courseBanners[1].image}
                alt="course"
                style={{width: '100%'}}
              />
            </div>
          )}
        </div>
        <BrandShareBottomSheet
          isOpen={isOpenShareSheet}
          setIsOpen={setIsOpenShareSheet}
          item={{
            title: '뉴뉴의 추천 데이트 코스',
            info: '단독 할인혜택으로 즐기는 추천 코스',
            imageList:
              courseBanners && courseBanners.length > 0
                ? [courseBanners[0].image || '']
                : [''],
          }}
        />
        {/* <div
          className={styles.container}
          style={{maxHeight: 'calc(100vh - 85px)', overflow: 'auto'}}>
          {!isSearching && <PopularBrands />}
          {isSearching && searchedBrandList.length > 0 && (
            <SearchResult keyword={search} searchedList={searchedBrandList} />
          )}
          {isSearching && searchedBrandList.length === 0 && <NoSearchList />}
        </div> */}
        <Layout.BottomNavigation />
      </div>
    </Layout.Main>
  );
}
