import React, {useEffect, useRef} from 'react';
import {Capacitor} from '@capacitor/core';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {useNavigate, useParams} from 'react-router-dom';

import {ReactComponent as WrongInfoIcon} from '@/assets/icons/brand_detail_wrong_info.svg';
import {ReactComponent as WrongInfoArrowIcon} from '@/assets/icons/brand_detail_wrong_info_arrow.svg';
import {ReactComponent as NaverMapIcon} from '@/assets/icons/naverModalIcon.svg';
import {ReactComponent as KakaoMapIcon} from '@/assets/icons/kakaoModalIcon.svg';
import {ReactComponent as RegisterIcon} from '@/assets/icons/register_brand.svg';
import {useBrandContext} from '@/services/contexts/brand/BrandContext';
import {openBrowserForLink} from '@/utils';

import styles from '../styles.module.scss';
import Layout from '../../../layouts';
import {
  BrandMapLocation,
  ToastMessage,
  UserGeoLocation,
  isOpenToast,
} from '../../../store';
import useNative from '../../../hooks/useNative';
import useLogger, {Action} from '../../../hooks/useLogger';

interface Props {
  placeInfo: PlaceInfoProps;
}

export default function PlaceInfo({placeInfo}: Props) {
  const {id, placeId} = useParams();
  const navigate = useNavigate();
  const sender = useLogger();
  const {Echo} = useNative();
  const mapElement = useRef<HTMLDivElement>(null);
  const {naver} = window as any;
  const {brandInfo} = useBrandContext();
  const [userGeoLocation, setUserGeoLocation] = useRecoilState(UserGeoLocation);
  const [locationInfo, setLocationInfo] =
    useRecoilState<number[]>(BrandMapLocation);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const initMap = () => {
    if (!mapElement.current || !window) {
      return;
    }
    if (placeInfo && placeInfo.address && mapElement.current && naver.maps) {
      naver.maps.Service.geocode(
        {
          address: placeInfo.address,
        },
        (status: any, response: NaverMapGeoCodeResponse) => {
          const {x, y} = response.result.items[1].point;
          setLocationInfo(() => {
            return [Number(x), Number(y)];
          });
        },
      );
    }
  };
  const copy = async (address: string) => {
    window.navigator.clipboard.writeText(`${address}`);
    setToastMessage('복사되었습니다.');
    setIsOpenToast(true);
  };
  const onGeoloationSucess = (position: any) => {
    if (position && position.coords) {
      setUserGeoLocation(() => {
        return [position.coords.longitude, position.coords.latitude];
      });
    }
  };
  const onGeolocationError = () => {
    setUserGeoLocation([-1, -1]);
  };
  const openLink = async (link: any) => {
    if (Capacitor.getPlatform() === 'web') {
      window.open(link, '_blank');
    } else {
      // await Browser.open({url: link});
      await Echo.echo({
        value: `instagram*${link}`,
      });
    }
  };

  const openMap = (serviceType: 'kakao' | 'naver' | 'google') => {
    if (brandInfo) {
      switch (serviceType) {
        case 'kakao':
          openLink(brandInfo?.locationKakao || '');
          break;
        case 'naver':
          openLink(brandInfo?.locationNaver || '');
          break;
      }
    }
  };

  useEffect(() => {
    if (placeInfo && mapElement.current) {
      initMap();
    }
    // 웹일경우 geoloation api로 현재위치 받아오기
    if (Capacitor.getPlatform() === 'web') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          onGeoloationSucess,
          onGeolocationError,
        );
      }
    }
  }, [placeInfo, mapElement]);
  useEffect(() => {
    if (locationInfo.length === 2) {
      let map: any = null;
      const location = new naver.maps.LatLng(locationInfo[1], locationInfo[0]);
      const mapOptions = {
        center: location,
        zoom: 18,
        zoomControl: false,
      };
      map = new naver.maps.Map('map', mapOptions);
      new naver.maps.Marker({
        position: location,
        map,
      });
    }
  }, [locationInfo, userGeoLocation]);
  return (
    <Layout.BrandCardView title={'브랜드 위치'}>
      <div className={styles.placeInfoWrapper}>
        <div ref={mapElement} className={styles.mapArea} id="map"></div>
        {brandInfo?.locationKakao && (
          <div className={styles.mapIcons}>
            <button
              className={styles.navermapBtn}
              onClick={() => openMap('naver')}>
              <NaverMapIcon fontSize={20} />
              <span>네이버 지도</span>
            </button>
            <button
              className={styles.kakaoMapBtn}
              onClick={() => openMap('kakao')}>
              <KakaoMapIcon fontSize={20} />
              <span>카카오 지도</span>
            </button>
          </div>
        )}
        <div
          className={`${styles.placeInfo} ${styles.address}`}
          style={{marginTop: '30px'}}>
          <span className={styles.icon}></span>
          <span className={styles.addressTxt}>{placeInfo.address}</span>
          <span className={styles.copy} onClick={() => copy(placeInfo.address)}>
            복사
          </span>
        </div>
        <div className={`${styles.placeInfo} ${styles.schedule}`}>
          <span className={styles.icon}></span>
          <div className={styles.scheduleInfo}>
            {placeInfo.openingHours.map(hour => {
              return <span key={hour}>{hour}</span>;
            })}
          </div>
        </div>
        <div className={`${styles.placeInfo} ${styles.call}`}>
          <span className={styles.icon}></span>
          <span>{placeInfo.brandTel}</span>
          <span
            className={styles.copy}
            onClick={() => copy(placeInfo.brandTel)}>
            복사
          </span>
        </div>
        <div
          className={`${styles.placeInfo} ${styles.links}`}
          style={{marginBottom: '30px'}}>
          <span className={styles.icon}></span>
          {placeInfo.brandLink && (
            <span
              className={styles.link}
              onClick={() => openLink(placeInfo.brandLink)}>
              인스타그램
            </span>
          )}
          {placeInfo.reserveLink && (
            <span
              className={styles.link}
              onClick={() => {
                sender({
                  _msg: 'bookingSBtn',
                  _screen: window.location.pathname,
                  _action: Action.CLICK,
                });
                openLink(placeInfo.reserveLink);
              }}>
              예약사이트
            </span>
          )}
        </div>
        <div style={{padding: '0 20px 4px 20px'}}>
          <button
            className={styles.wrongInfoBtn}
            onClick={() =>
              navigate(`/wrong-info/${id}/${placeId}`, {
                replace: true,
              })
            }>
            <div className="flex items-center">
              <WrongInfoIcon />
              <span style={{marginLeft: '8px'}}>
                잘못된 정보가 있다면 알려주세요
              </span>
            </div>
            <WrongInfoArrowIcon />
          </button>
        </div>
        <div style={{padding: '0 20px 40px 20px'}}>
          <button
            className={styles.wrongInfoBtn}
            onClick={() => openBrowserForLink('https://www.knewnewbiz.co.kr/')}>
            <div className="flex items-center">
              <RegisterIcon />
              <span style={{marginLeft: '8px'}}>
                내 브랜드를 등록하고 싶어요
              </span>
            </div>
            <WrongInfoArrowIcon />
          </button>
        </div>
      </div>
    </Layout.BrandCardView>
  );
}
