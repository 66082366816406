import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {Browser} from '@capacitor/browser';
import dayjs from 'dayjs';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import ContentsHeader from '@/layouts/ContentsHeader';
import {addComma} from '@/utils';
import useKakao from '@/hooks/useKakao';
import useNative from '@/hooks/useNative';
import ContactModal from '@/components/contactModal';
import MapModal from '@/components/mapModal';
import {
  IsOpenBlackBtnConfirmModal,
  isOpenToast,
  ToastMessage,
  user,
} from '@/store';
import BlackButtonConfirmModal from '@/components/BlackButtonConfirmModal';
import {alterPayments, getPaymentsDetail} from '@/services/magazine';

import OrderCancelModal from './components/OrderCancelModal';
import styles from './styles.module.scss';

export default function OrderDetail() {
  const {Echo} = useNative();
  const kakao = useKakao();
  const location = useLocation();
  const navigator = useNavigate();
  const accessToken = useRecoilValue(user);
  const setIsOpenConFirmModal = useSetRecoilState(IsOpenBlackBtnConfirmModal);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isOpenContactModal, setIsOpenContactModal] = useState(false);
  const [isOpenMapModal, setIsOpenMapModal] = useState(false);
  const [isBlackBtnDisabled, setIsBlackBtnDisabled] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [orderInfo, setOrderInfo] = useState<OrderListItem>();
  const [status, setStatus] = useState<
    | '취소 완료'
    | '기간 만료'
    | '사용 완료'
    | '취소 요청'
    | '취소 불가'
    | '구매 완료'
  >('구매 완료');
  const openBrowser = async () => {
    if (Capacitor.getPlatform() === 'web') {
      window.open('http://pf.kakao.com/_qmUqG/chat', '_blank');
    } else {
      await Browser.open({
        url: 'http://pf.kakao.com/_qmUqG/chat',
      });
    }
  };
  const priceInfo = useMemo(() => {
    const _pInfo = {
      productPrice: 0,
      discountPrice: 0,
    };
    if (orderInfo) {
      const discountPrice = orderInfo.discountAmount || 0;
      const productPrice = Number(orderInfo.purchaseAmount || 0);
      const deliveryFee = orderInfo.deliveryFee || 0;
      _pInfo.productPrice = productPrice + discountPrice - deliveryFee;
      _pInfo.discountPrice = discountPrice;
    }
    return _pInfo;
  }, [orderInfo]);

  const statusCode = useMemo(() => {
    let code = '';
    const _status = status;
    switch (_status) {
      case '취소 요청':
      case '취소 완료':
        code = 'cancel';
        break;
      case '기간 만료':
      case '사용 완료':
        code = 'used';
        break;
      case '취소 불가':
        code = 'non-cancel';
        break;
      default:
        code = 'done';
        break;
    }
    return code;
  }, [status]);

  const statusLabel = useMemo(() => {
    let label = '';
    const _status = status;
    switch (_status) {
      case '취소 불가':
        label = '구매 완료';
        break;
      default:
        label = status;
        break;
    }
    return label;
  }, [status]);

  const crewConfirmObj = useMemo(() => {
    let message = '';
    let disabled = false;
    const _status = status;
    switch (_status) {
      case '취소 완료':
        break;
      case '기간 만료':
        message = '유효 기간 만료';
        disabled = true;
        break;
      case '사용 완료':
        message = '사용 완료';
        disabled = true;
        break;
      case '취소 요청':
        break;
      default:
        message = '직원 확인';
        break;
    }
    return {
      message,
      disabled,
    };
  }, [status]);

  const makeUrl = () => {
    const host =
      process.env.NODE_ENV !== 'development'
        ? 'https://knewnew.co.kr'
        : 'https://dev.knewnew.co.kr';
    return `${host}/popup/${(location.state.item.popup as MagazineItem).id}`;
  };

  const getOptions = () => {
    const items: {item: string; itemOp: string}[] = [];

    items.push({
      item: '-',
      itemOp: orderInfo?.options[0].value ?? orderInfo?.options[0].dateValue,
    });
    items.push({
      item: '-',
      itemOp:
        orderInfo?.options[0].child[0].value ??
        orderInfo?.options[0].child[0].dateValue,
    });
    items.push({
      item: '-',
      itemOp: `수량 ${orderInfo?.purchaseCount}개`,
    });

    items.push({item: '-', itemOp: orderInfo?.userName ?? ''});
    return items;
  };

  const shareKakao = async () => {
    if (Capacitor.getPlatform() === 'web') {
      kakao.shareOrder({
        title: orderInfo?.popup.title ?? '',
        imageUrl: orderInfo?.popup.imageUrl ?? '',
        link: makeUrl(),
        items: getOptions(),
      });
    } else {
      await Echo.echo({
        value: `share*${orderInfo?.popup.title}*${''}*${
          orderInfo?.popup.imageUrl || ''
        }*${makeUrl()}*${`/popup/${orderInfo?.popup.id}`}`,
      });
    }
  };

  const requestPay = async (requestParam: PaymentRequestParam) => {
    try {
      await alterPayments(orderInfo?.id || -1, requestParam, accessToken);
      setIsOpenConFirmModal(false);
      setIsOpenToast(true);
      setToastMessage('주문 취소가 완료되었습니다.');
      setStatus('취소 요청');
    } catch (e) {
      setIsOpenConFirmModal(false);
      navigator('/cancel/fail');
    }
  };

  const getDetailInfo = async (id: string) => {
    const res = await getPaymentsDetail(id, accessToken);
    if (res) {
      setOrderInfo(res.data);
      setStatus(res.data.usedStatus);
    }
  };
  const cancelPayments = async () => {
    const requestParam: PaymentRequestParam = {
      ...(orderInfo || {}),
      popup: orderInfo?.popup.id || -1,
      option: orderInfo?.option || -1,
      orderId: orderInfo?.orderId || '',
      orderName: orderInfo?.orderName || '',
      paymentKey: orderInfo?.paymentKey || '',
      purchaseStatus: '결제 취소',
      cancelReason: selectedReason as
        | 'typo'
        | 'schedule'
        | 'price'
        | 'appeal'
        | 'else',
    };
    setIsBlackBtnDisabled(true);
    await requestPay(requestParam);
    setIsBlackBtnDisabled(false);
  };

  useEffect(() => {
    console.log(123);
    if (location.state) {
      // setOrderInfo(location.state.item);
      getDetailInfo(location.state.item.id);
      // setStatus(location.state.status);
    }
  }, [location.state]);
  return (
    <div className="main" style={{minHeight: '100vh', position: 'relative'}}>
      <ContentsHeader
        txt={'주문 상세'}
        isPopup
        isOption="none"
        isBottomLine=""
        back={() => navigator(-1)}
      />
      {orderInfo && (
        <div className={`${styles.success_info} ${styles.detail_info}`}>
          <div className={`${styles.product_info} ${styles[statusCode]}`}>
            <div className={styles.header}>
              <div className={styles.statusInfo}>
                <h2 className={styles.status}>{statusLabel}</h2>
                {!orderInfo.address1 && (
                  <span className={styles.expired}>
                    {(orderInfo.expired || orderInfo.approved) &&
                      `${dayjs(orderInfo.expired || orderInfo.approved).format(
                        'YYYY-MM-DD',
                      )}까지`}
                  </span>
                )}
              </div>
            </div>
            <div className={styles.product}>
              <div
                className={styles.img_wrapper}
                style={{
                  borderRadius: '5px',
                  background: `url('${orderInfo.popup.imageUrl}') no-repeat center / cover`,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigator(
                    `/popup/${(location.state.item.popup as MagazineItem).id}`,
                  )
                }></div>
              <div className={styles.txt_wrapper}>
                <h2>{orderInfo.popup.title}</h2>
                <div className={styles.option_info}>
                  <div className={styles.option}>
                    {orderInfo.options[0].value ??
                      orderInfo.options[0].dateValue}
                  </div>
                  <div className={styles.option}>
                    {orderInfo.options[0].child[0].value ??
                      orderInfo.options[0].child[0].dateValue}
                  </div>
                  <div
                    className={
                      styles.option
                    }>{`수량 ${orderInfo.purchaseCount}개`}</div>
                </div>
              </div>
            </div>
            {!orderInfo.address1 && (
              <div
                style={{display: statusCode === 'cancel' ? 'none' : 'block'}}>
                <div className={styles.confirmBtnWrapper}>
                  {crewConfirmObj.message === '직원 확인' && (
                    <>
                      <div className={styles.tooltip_area}>
                        <div className={styles.tooltip}>
                          구매 정보를 친구에게 공유해보세요
                        </div>
                      </div>
                      <button
                        disabled={crewConfirmObj.disabled}
                        className={styles.share_btn}
                        onClick={() => shareKakao()}
                        style={{height: '46px'}}
                      />
                    </>
                  )}

                  <button
                    className={styles.confirmBtn}
                    disabled={crewConfirmObj.disabled}
                    onClick={() =>
                      navigator('/order/numbers', {
                        state: {
                          id: orderInfo.id,
                          name: orderInfo.userName,
                          option1:
                            orderInfo.options[0].value ??
                            orderInfo.options[0].dateValue,
                          option2:
                            orderInfo.options[0].child[0].value ??
                            orderInfo.options[0].child[0].dateValue,
                          status: location.state.status,
                          item: location.state.item,
                          fromOrderList: true,
                        },
                      })
                    }>
                    {crewConfirmObj.message}
                  </button>
                </div>
                <div className={styles.confirmWarning}>
                  <p>매장 방문 시 화면을 현장 직원에게 보여주세요.</p>
                </div>
              </div>
            )}
          </div>
          {/* 안내사항 */}
          {orderInfo.guideTxt && (
            <div className={styles.info_area}>
              <h2>안내사항</h2>
              <p className={styles.info_text}>{orderInfo.guideTxt}</p>
              {orderInfo.guideUrl && (
                <a
                  href={orderInfo.guideUrl}
                  target="_black"
                  className={styles.info_link}>
                  {orderInfo.guideUrl}
                </a>
              )}
            </div>
          )}
          {!orderInfo.address1 && (
            <div className={styles.location_info_row}>
              {orderInfo.popupAddress && (
                <div
                  className={styles.info_row}
                  onClick={() => setIsOpenMapModal(true)}>
                  <div className={styles.label}>
                    <span className={styles.icon}></span>
                    <span className={styles.txt}>매장 위치</span>
                  </div>
                  <span className={styles.arrow_right}></span>
                </div>
              )}
              {(orderInfo.popupReserveLink || orderInfo.popupReserveNumber) && (
                <div
                  className={styles.info_row}
                  onClick={() => setIsOpenContactModal(true)}>
                  <div className={styles.label}>
                    <span className={styles.icon}></span>
                    <span className={styles.txt}>날짜 선택</span>
                  </div>
                  <span className={styles.arrow_right}></span>
                </div>
              )}
            </div>
          )}
          {orderInfo.address1 ? (
            <div className={styles.buyer_info_row}>
              <h2>배송지 정보</h2>
              <div className={styles.info_row}>
                <div className={styles.label}>이름</div>
                <div className={styles.contents}>{orderInfo.userName}</div>
              </div>
              <div className={styles.info_row}>
                <div className={styles.label}>휴대폰 번호</div>
                <div className={styles.contents}>{orderInfo.userPhone}</div>
              </div>
              {orderInfo.orderRequest && (
                <div className={styles.info_row}>
                  <div className={styles.label}>주문 요청사항</div>
                  <div className={styles.contents}>
                    {orderInfo.orderRequest}
                  </div>
                </div>
              )}
              <div className={styles.info_row}>
                <div className={styles.label}>주소</div>
                <div
                  className={
                    styles.contents
                  }>{`[${orderInfo.postCode}]${orderInfo.address1} ${orderInfo.address2}`}</div>
              </div>
              <div className={styles.info_row}>
                <div className={styles.label}>배송 요청사항</div>
                <div className={styles.contents}>{orderInfo.requests}</div>
              </div>
              {orderInfo.orderRequest && (
                <div className={styles.info_row}>
                  <div className={styles.label}>주문 요청사항</div>
                  <div className={styles.contents}>
                    {orderInfo.orderRequest}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.buyer_info_row}>
              <h2>구매자 정보</h2>
              <div className={styles.info_row}>
                <div className={styles.label}>이름</div>
                <div className={styles.contents}>{orderInfo.userName}</div>
              </div>
              <div className={styles.info_row}>
                <div className={styles.label}>휴대폰 번호</div>
                <div className={styles.contents}>{orderInfo.userPhone}</div>
              </div>
              {orderInfo.orderRequest && (
                <div className={styles.info_row}>
                  <div className={styles.label}>주문 요청사항</div>
                  <div className={styles.contents}>
                    {orderInfo.orderRequest}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className={styles.pay_info_row}>
            <h2>결제 정보</h2>
            <div className={`${styles.info_row} ${styles.with_sub}`}>
              <div className={styles.label}>결제금액</div>
              <div className={styles.contents}>
                {addComma(orderInfo.purchaseAmount || 0)}원
              </div>
            </div>
            <div className={styles.sub_info_row}>
              <div>∙ 상품 금액</div>
              <div>{addComma(priceInfo.productPrice)}원</div>
            </div>
            <div className={styles.sub_info_row}>
              <div>∙ 쿠폰 할인</div>
              <div>{addComma(priceInfo.discountPrice) + '원'}</div>
            </div>
            {orderInfo.address1 && (
              <div className={styles.sub_info_row}>
                <div>∙ 배송비</div>
                <div>{addComma(orderInfo.deliveryFee || 0) + '원'}</div>
              </div>
            )}
            <div
              className={styles.info_row}
              style={{
                display:
                  statusCode === 'cancel' &&
                  location?.state?.status === '취소 완료'
                    ? 'flex'
                    : 'none',
              }}>
              <div className={styles.label}>취소금액</div>
              <div className={styles.contents} style={{color: '#FF3E48'}}>
                {'-' + addComma(orderInfo.purchaseAmount || 0)}원
              </div>
            </div>
            <div
              className={styles.info_row}
              style={{
                borderTop: '1px solid #F7F7FC',
              }}>
              <div className={styles.label}>결제수단</div>
              <div className={styles.contents}>
                {orderInfo.card?.issuerName || '-'}
              </div>
            </div>
            <div className={styles.info_row}>
              <div className={styles.label}>결제일시</div>
              <div className={styles.contents}>
                {dayjs(orderInfo.created).format('YYYY-MM-DD HH:mm:ss')}
              </div>
            </div>
          </div>
          <div className={styles.alert_message}>
            {`* 결제 및 계좌 안내 시 상호명은 (주)뉴뉴로 표기됩니다.`}
          </div>

          <div className={styles.btn_wrapper}>
            {!orderInfo.address1 && (
              <button
                className={styles.btn_request_cancel}
                onClick={() => setIsOpenCancelModal(true)}
                style={{
                  display:
                    statusCode === 'done' || statusCode === 'non-cancel'
                      ? 'block'
                      : 'none',
                }}
                disabled={statusCode === 'non-cancel'}>
                {statusCode === 'non-cancel' ? ' 취소 불가' : '주문 취소'}
              </button>
            )}
            <button className={styles.btn_personal_question}>
              <span className={styles.kakao_icon}>&nbsp;</span>
              <span style={{marginLeft: '5px'}} onClick={() => openBrowser()}>
                1:1 문의
              </span>
            </button>
          </div>
          {statusCode === 'non-cancel' && (
            <div className={styles.cancelWarning}>
              <p>취소 및 환불 기간이 지난 상품입니다.</p>
            </div>
          )}
        </div>
      )}
      <OrderCancelModal
        item={location.state.item}
        isOpen={isOpenCancelModal}
        toggleOpen={(is: boolean) => setIsOpenCancelModal(is)}
        openCancelConfirmModal={(is: boolean) => setIsOpenConFirmModal(is)}
        changeOptions={(txt: string) => setSelectedReason(txt)}
      />
      {(orderInfo?.popupReserveNumber || orderInfo?.popupReserveLink) && (
        <ContactModal
          isOpen={isOpenContactModal}
          setIsOpen={(is: boolean) => setIsOpenContactModal(is)}
          info={{
            tel: orderInfo.popupReserveNumber || '',
            link: orderInfo.popupReserveLink || '',
          }}
        />
      )}
      {orderInfo?.popupAddress && (
        <MapModal
          isOpen={isOpenMapModal}
          setIsOpen={(is: boolean) => setIsOpenMapModal(is)}
          placeInfo={{
            address: orderInfo.popupAddress,
            openingHours: [''],
            brandTel: '',
            brandLink: '',
            reserveLink: '',
            name: orderInfo.popup.brands?.title || '',
          }}
        />
      )}
      <BlackButtonConfirmModal
        callback={() => cancelPayments()}
        isBtnDisable={isBlackBtnDisabled}
      />
    </div>
  );
}
