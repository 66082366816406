/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef} from 'react';
import {
  loadTossPayments,
  TossPaymentsWidgets,
} from '@tosspayments/tosspayments-sdk';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import {addComma} from '@/utils';
import Layout from '@/layouts';

import styles from './styles.module.scss';

interface StateItems {
  magazineId: number;
  options: any[];
  price: number;
  name: string;
  phoneNumber: string;
  amount: number;
  optionId: number | string;
  title: string;
  pk: string;
  ck: string;
  magazine: MagazineItem;
  selectedDate: any;
  selectedTime: any;
  orderId: string | number;
}

export default function Payments() {
  const paymentWidgetRef = useRef<TossPaymentsWidgets | null>(null);
  const navigator = useNavigate();
  const location = useLocation();
  const [searchParam] = useSearchParams();
  const localStorageKey = 'WEB_PAYMENT_INFO';
  const setLocationInfo = () => {
    if (location.state) {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          ...location.state,
        }),
      );
    }
  };

  const getState = () => {
    const item = JSON.parse(
      localStorage.getItem(localStorageKey) || '{}',
    ) as StateItems;
    if (Object.keys(item).length > 0) {
      return {
        ...item,
      };
    }
    const {
      price,
      title,
      pk,
      ck,
      magazine,
      selectedDate,
      selectedTime,
      amount,
      name,
      phoneNumber,
      optionId,
      orderId,
      couponId,
      discountPrice,
      productPrice,
      address,
      postCode,
      detailAddress,
      userOption,
      deliveryFee,
      orderRequest,
    } = location.state;
    return {
      title,
      price,
      pk,
      ck,
      magazine,
      selectedDate,
      selectedTime,
      amount,
      name,
      phoneNumber,
      optionId,
      orderId,
      couponId,
      discountPrice,
      productPrice,
      address,
      postCode,
      detailAddress,
      userOption,
      deliveryFee,
      orderRequest,
    };
  };

  const order = async () => {
    const paymentWidget = paymentWidgetRef.current;

    try {
      await paymentWidget?.requestPayment({
        orderId: getState().orderId || 'not_found_order_id',
        orderName: getState().title || '',
        successUrl: `${window.location.origin}/order/detail/payments/${
          getState().magazine.id
        }?orderId=${getState().orderId}&orderName=${getState().title}&option=${
          getState().optionId
        }&magazineId=${getState().magazine.id}`,
        failUrl: `${window.location.origin}/fail`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const makeWidget = async () => {
    // const paymentWidget = await loadPaymentWidget(getState().pk, getState().ck);
    const tossPayments = await loadTossPayments(getState().pk);
    const paymentWidget = tossPayments.widgets({
      customerKey: getState().ck,
    });
    paymentWidget.setAmount({
      currency: 'KRW',
      value: getState().price,
    });
    await Promise.all([
      paymentWidget.renderPaymentMethods({
        selector: '#payment-widget',
      }),
      paymentWidget.renderAgreement({
        selector: '#agreement',
        variantKey: 'AGREEMENT',
      }),
    ]);

    paymentWidgetRef.current = paymentWidget;
  };
  useEffect(() => {
    setLocationInfo();
    if (getState().ck) {
      makeWidget();
    }
  }, [getState().ck]);

  useEffect(() => {
    if (searchParam.get('orderId')) {
      console.log(getState());
      navigator(`/order/process?${searchParam.toString()}`, {
        state: {
          magazine: getState().magazine as MagazineItem,
          options: [
            getState().selectedDate.key || '',
            getState().selectedTime.key || '',
            `수량 ${getState().amount || 0}개`,
          ],
          price: getState().price,
          name: getState().name,
          phoneNumber: getState().phoneNumber,
          amount: getState().amount,
          optionId: getState().optionId,
          couponId: getState().couponId,
          discountPrice: getState().discountPrice,
          productPrice: getState().productPrice,
          postCode: getState().postCode,
          address: getState().address,
          detailAddress: getState().detailAddress,
          userOption: getState().userOption,
          deliveryFee: getState().deliveryFee,
          orderRequest: getState().orderRequest,
        },
      });
    }
  }, [searchParam]);
  return (
    <Layout.Main>
      <div className={`${styles.payment_widget}`}>
        <div className={styles.header}>
          <span>주문 / 결제</span>
        </div>
        <div className={`${styles.payment_info}`}>
          <div className={`${styles.price_wrapper}`} id="price">
            {`${addComma(getState().price || 0)}원`}
          </div>
          <div className={`${styles.name}`} id="orderName">
            {getState().title || ''}
          </div>
        </div>
        <div id="payment-widget"></div>
        <div id="agreement"></div>
        <div className={`${styles.payment_btn_wrapper}`}>
          <button id="payment-request-button" onClick={() => order()}>
            결제하기
          </button>
        </div>
      </div>
    </Layout.Main>
  );
}
