const brandKeys = {
  getBrandDetail: (payload: any) => ['getBrandDetail', payload],
  getBenefits: (payload: BrandBenefitParams) => ['getBenefits', payload],
  getSqureBrandList: (payload: SquareBrandsParam) => [
    'getSqureBrandList',
    payload,
  ],
  getHomeBanners: () => ['getHomeBanners'],
  getSubCuration: () => ['getSubCuration'],
  getCOurseBanners: () => ['getCourseBanners'],
  getCourseList: () => ['getCourseList'],
  getCurationList: (payload: {magazine_collection_id: number}) => [
    'getCurationList',
    payload,
  ],
};

export default brandKeys;
