import React from 'react';

import {useBrandContext} from '@/services/contexts/brand/BrandContext';
import {ReactComponent as ArrowRight} from '@/assets/icons/more-right-arrow.svg';

import styles from './styles.module.scss';

interface Props {
  moreImage: () => void;
}
export default function EditorTip({moreImage}: Props) {
  const {brandInfo} = useBrandContext();
  const editorTip = brandInfo?.infoTip?.info;
  const editorTipTitle = brandInfo?.infoTip?.title;
  const images = brandInfo?.infoTip?.imageList;
  return brandInfo?.infoTip ? (
    <div className={styles.editorTip}>
      <h3>{`EDITOR'S TIP`}</h3>
      <h2>{`${editorTipTitle}`}</h2>
      <ul>
        {Object.keys(editorTip || {}).map((key, index) => (
          <li
            key={`editor_tip_${index}`}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <span style={{fontSize: '14px'}}>{`0${index + 1}`}&nbsp;</span>
            <span>
              {editorTip?.[key as keyof typeof editorTip]?.replace(/\n/g, ' ')}
            </span>
          </li>
        ))}
      </ul>
      <div className={styles.imageWrapper}>
        <img src={images?.[0]} alt="editor_tip_image" />
        <button className={styles.shortcutBtn} onClick={() => moreImage()}>
          무드 더보기
          <ArrowRight />
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}
