import React, {useCallback, useEffect, useRef, useState} from 'react';
import {motion} from 'framer-motion';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';

import Layout from '@/layouts';
import {
  AddressList,
  isOpenAddressSelector,
  isOpenToast,
  ToastMessage,
  user,
  UserInfo,
} from '@/store';
import useGeoLocation from '@/hooks/useGeoLocation';
import AddressSelector from '@/components/community/AddressSelector';
import BrandLoginModal from '@/components/common/BrandLoginModal';
import GeolocationModal from '@/components/common/GeolocationModal';
import useNative from '@/hooks/useNative';
import Header from '@/layouts/Header';
import MarkerIcon from '@/assets/icons/map_marker.svg';
import SelectedBrandIcon from '@/assets/icons/selected_brand_icon.svg';
import {ReactComponent as RefreshIcon} from '@/assets/icons/location_refresh.svg';
import {ReactComponent as CurrentPositionIcon} from '@/assets/icons/location_outline.svg';
import MyPositionIcon from '@/assets/icons/current_position.svg';
import LocationLoader from '@/components/common/Loader/LocationLoader';
import {extractRegionAndNeighborhood, isMobile} from '@/utils';
import LoginAlertModal from '@/components/LoginAlertModal/LoginAlertModal';

import styles from './styles.module.scss';
import BrandList from './BrandList';

export default function Map() {
  const mapElement = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement | null>(null);
  const INITIAL_LIST_HEIGHT = isMobile() ? 330 : 314;
  const LIST_MIN_HEIGHT = 85;

  const {
    isPossibleGeolocation: isActiveCurrentPosition,
    isGeolocationModalOpen,
    permissionState,
    isAreaSelected,
    setIsGeolocationModalOpen,
    setIsAreaSelected,
    getCurrentPosition,
    setCoordinates,
    coordinates,
    setPrevCoords,
    height,
    setHeight,
    scrollRef,
    isScrollLock,
    setIsScrollLock,
    brands,
    zoomLevel,
    setZoomLevel,
    isLoading,
    fetchOptions,
    setFetchOptions,
    isRenderComplete,
    setIsRenderComplete,
    filteredBrandList,
    originCoords,
    movedCoordinates,
    setMovedCoordinates,
  } = useGeoLocation();
  const navigator = useNavigate();
  const {Echo} = useNative();
  const accessToken = useRecoilValue(user);
  const userInfo = useRecoilValue(UserInfo);
  const addressList = useRecoilValue(AddressList);
  const setIsOpen = useSetRecoilState(isOpenAddressSelector);
  const setIsOpenToastModal = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [, setIsLoad] = useState(false);
  const [addr2Str, setAddr2Str] = useState('성동구');
  const [addr3Str, setAddr3Str] = useState('성수2가1동');
  const [isShowReload, setIsShowReload] = useState(false);
  const [isinit, setisInit] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState<BrandListItemType>(); // 선택된 마커 저장
  const [selectedMarker, setSelectedMarker] = useState<any>();
  const [markers, setMarkers] = useState<any[]>([]);
  const [myPositionMarker, setMyPositionMarker] = useState<any>();
  const [map, setMap] = useState<any>();
  const selectedMarkerRef = useRef<any>(null);
  const mapCenterChangeRef = useRef<any>(null);
  const reLoadBrandRef = useRef<boolean>(true);
  const markersRef = useRef<any[]>([]);
  const {naver} = window as any;
  const [isOpenLoginAlertModal, setIsOpenLoginAlertModal] = useState(false);
  const loadLocationInfo = async () => {
    getCurrentPosition && (await getCurrentPosition());
    setIsShowReload(false);
  };

  const BRAND_LIST_MAX_HEIGHT = (() => {
    const _BRAND_LIST_MAX_HEIGHT = window.innerHeight - 186;
    return Capacitor.getPlatform() === 'android'
      ? _BRAND_LIST_MAX_HEIGHT + 24
      : _BRAND_LIST_MAX_HEIGHT;
  })();

  const handleDrag = (_: any, info: any) => {
    if (
      (!isScrollLock && scrollRef.current?.scrollTop !== 0) ||
      !!selectedMarkerRef.current
    ) {
      return; // 스크롤이 맨 위가 아니라면 동작하지 않음
    }
    const newHeight = height - info.delta.y;
    const _height = Math.max(
      INITIAL_LIST_HEIGHT,
      Math.min(BRAND_LIST_MAX_HEIGHT, newHeight),
    );
    setHeight(_height);
  };

  const handleDragEnd = (_: any, info: any) => {
    const velocity = info.velocity.y;

    if (velocity > 0) {
      // 아래로 스와이프 시 조건 확인
      if (!isScrollLock && scrollRef.current?.scrollTop !== 0) {
        return; // 스크롤이 맨 위가 아니라면 동작하지 않음
      }
      setHeight(INITIAL_LIST_HEIGHT); // 최소 높이로 설정
    } else if (velocity < 0) {
      setHeight(BRAND_LIST_MAX_HEIGHT); // 최대 높이로 설정
    }
  };

  const reverseGeocode = (latitude: number, longitude: number) => {
    const {naver} = window as any;

    naver.maps.Service.reverseGeocode(
      {
        coords: new naver.maps.LatLng(latitude, longitude),
      },
      function (status: any, response: any) {
        if (status !== naver.maps.Service.Status.OK) {
          alert('Something went wrong!');
          return;
        }

        const result = response.v2; // 검색 결과의 컨테이너
        const address = result.address; // 검색 결과로 만든 주소
        const addrInfo = extractRegionAndNeighborhood(
          address.jibunAddress.trim(),
        );
        setAddr2Str(addrInfo.region as string);
        setAddr3Str(addrInfo.neighborhood as string);
      },
    );
  };

  const changeBoundary = useCallback(() => {
    if (map) {
      const {_sw, _ne} = map.getBounds();
      setFetchOptions({
        ...fetchOptions,
        point1: [_sw._lat, _sw._lng].join(','),
        point2: [_ne._lat, _ne._lng].join(','),
      });
    }
  }, [map]);

  const centerChangeListener = () => {
    return {
      append: (_map: any) => {
        if (mapCenterChangeRef.current) {
          centerChangeListener().remove();
        }
        return naver.maps.Event.addListener(_map, 'center_changed', () => {
          setIsShowReload(true);
          setIsAreaSelected && setIsAreaSelected(true);
          setHeight(INITIAL_LIST_HEIGHT);
          // setIsRenderComplete(false);
          // movedCoordinatesRef.current = {
          //   latitude: _map.getCenter()._lat,
          //   longitude: _map.getCenter()._lng,
          //   error: null,
          // };
          setMovedCoordinates({
            latitude: _map.getCenter()._lat,
            longitude: _map.getCenter()._lng,
            error: null,
          });
          // 현재 zoom level 저장
          const currentZoom = _map.getZoom();
          setZoomLevel(currentZoom);
        });
      },
      remove: () => {
        if (mapCenterChangeRef.current) {
          naver.maps.Event.removeListener(mapCenterChangeRef.current);
          mapCenterChangeRef.current = null;
        }
      },
    };
  };

  const makeMap = (lat: number, lng: number) => {
    const latitude = movedCoordinates.latitude || lat;
    const longitude = movedCoordinates.longitude || lng;
    const mapOptions = {
      center: new naver.maps.LatLng(latitude, longitude),
      zoom: zoomLevel,
      zoomControl: false,
      scaleControl: false,
      logoControl: true,
      mapDataControl: false,
      logoControlOptions: {
        position: naver.maps.Position.TOP_RIGHT, // 우상단으로 로고 이동
      },
    };
    const _map = new naver.maps.Map('map', mapOptions);
    mapCenterChangeRef.current = centerChangeListener().append(_map);
    // zoom_changed 이벤트 리스너 추가
    naver.maps.Event.addListener(_map, 'zoom_changed', () => {
      const newZoom = _map.getZoom();
      setZoomLevel(newZoom);
    });
    setMap(() => _map);
    // setIsRenderComplete(true);
    // changeBoundary();
    return _map;
  };

  const makeMyPositionMarker = (lat: number, lng: number, map: any) => {
    const myMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(lat, lng),
      map, // 반드시 map 객체를 생성한 후 설정
      icon: {
        content: `
          <div style="
            width: 48px; 
            height: 48px; 
            background: url('${MyPositionIcon}') no-repeat center center; 
            background-size: cover;">
          </div>
        `,
        anchor: new naver.maps.Point(24, 24), // 중심 좌표
      },
    });
    setMyPositionMarker(myMarker);
  };

  const initMap = async () => {
    if (!mapElement.current || !window) {
      return;
    }
    if (isinit) {
      return;
    }

    let location = null;
    const {longitude, latitude} = coordinates;
    // 현재위치 or 기본위치
    // 퍼미션이 켜져있는경우
    if (permissionState?.location) {
      if (longitude && latitude) {
        location = new naver.maps.LatLng(latitude, longitude);
      }
    }
    if (!location) {
      makeMap(37.547222, 127.047306);
      return;
    }
    // location정보 저장
    if (latitude && longitude) {
      setPrevCoords && setPrevCoords([latitude, longitude]);
      makeMap(latitude, longitude);
    }

    setisInit(true);
  };

  const updateAddress = (
    address2: string,
    address3: string,
    coords?: number[],
  ) => {
    setSelectedBrand(undefined);
    setSelectedMarker(undefined);
    setIsRenderComplete(false);
    setMovedCoordinates({
      latitude: null,
      longitude: null,
      error: null,
    });
    setZoomLevel(15);
    setIsAreaSelected && setIsAreaSelected(true);
    if (coords && setCoordinates) {
      setCoordinates({
        latitude: coords[0],
        longitude: coords[1],
        error: null,
      });
    }
    setisInit(false);
    reLoadBrandRef.current = true;
  };

  const resetAllMarkers = () => {
    if (!markersRef.current || markersRef.current.length === 0) return;

    markersRef.current.forEach(marker => {
      if (marker && marker.setMap) {
        marker.setIcon({
          content: `
            <div style="
              width: 20px; 
              height: 20px; 
              background: url('${MarkerIcon}') no-repeat center center; 
              background-size: cover;">
            </div>
          `,
          anchor: new naver.maps.Point(10, 10),
        });
      }
    });
  };

  const handleMarkerClick = (marker: any, brand: BrandListItemType) => {
    const content = `
      <div style="
        width: 24px; 
        height: 24px; 
        background: url('${SelectedBrandIcon}') no-repeat center center; 
        background-size: cover;">
      </div>
    `;
    const point = 12;
    let _marker;
    resetAllMarkers();

    //   marker가 맵 클릭이벤트에 바인딩 되어있을 경우
    if (!brand && marker.domEvent) {
      setSelectedBrand(undefined);
      setSelectedMarker(undefined);
      _marker = selectedMarkerRef.current;
      setHeight(LIST_MIN_HEIGHT);
    } else {
      setHeight(INITIAL_LIST_HEIGHT);
      setSelectedBrand(brand);
      setSelectedMarker(marker);
      _marker = marker;
    }
    _marker?.setIcon({
      content,
      anchor: new naver.maps.Point(point, point),
    });
  };

  // 마커 생성 및 이벤트 등록 함수
  const createMarkerWithListener = (brand: BrandListItemType, map: any) => {
    const marker = new naver.maps.Marker({
      position: new naver.maps.LatLng(brand.lat, brand.lon),
      map,
      icon: {
        content: `
          <div style="
            width: 20px; 
            height: 20px; 
            background: url('${MarkerIcon}') no-repeat center center; 
            background-size: cover;">
          </div>
        `,
        anchor: new naver.maps.Point(10, 10), // 중심 좌표
      },
    });
    naver.maps.Event.addListener(marker, 'click', () =>
      handleMarkerClick(marker, brand),
    );
    return marker;
  };

  const clearMarkers = () => {
    if (markers.length === 0) return;
    markers.forEach((marker: any) => marker.setMap(null)); // 지도에서 제거
    markers.length = 0; // 배열 초기화
  };

  const changeCoordinates = () => {
    if (map) {
      const center = map.getCenter();
      setCoordinates({
        latitude: center._lat,
        longitude: center._lng,
        error: null,
      });
      setSelectedMarker(undefined);
      setSelectedBrand(undefined);
      reverseGeocode(center._lat, center._lng);
    }
  };

  const changeMapPosition = (lat: number, lng: number) => {
    if (map) {
      const newCenter = new naver.maps.LatLng(lat, lng);
      map.setCenter(newCenter);
    }
  };

  const makeBrandMarker = (list: BrandListItemType[]) => {
    clearMarkers();
    setMarkers([]);
    const _markers: any[] = [];
    if (list) {
      list?.forEach(brand => {
        const m = createMarkerWithListener(brand, map);
        _markers.push(m);
      });
      setMarkers(_markers);
    }
    if (map) {
      naver.maps.Event.addListener(map, 'click', handleMarkerClick);
    }
  };

  const requestPostionAccessAllowPop = () => {
    if (Capacitor.getPlatform() !== 'web') {
      setIsGeolocationModalOpen(true);
    } else {
      setIsOpenToastModal(true);
      setToastMessage('브라우저 설정에서 위치정보 사용을 허용해주세요');
    }
  };

  const extraHeight = (() => {
    const FIXED_HEIGHT = '186px';
    const androidHeight = `${FIXED_HEIGHT} + var(--status-bar-height)`;
    return Capacitor.getPlatform() === 'android' ? androidHeight : FIXED_HEIGHT;
  })();

  useEffect(() => {
    selectedMarkerRef.current = selectedMarker;
  }, [selectedMarker]);

  useEffect(() => {
    markersRef.current = markers;
  }, [markers]);

  // 맵, 브랜드리스트가 모두 호출되었다면 마커 그리기
  useEffect(() => {
    if (map) {
      makeBrandMarker(filteredBrandList || []);
      if (originCoords) {
        const {longitude, latitude} = originCoords;
        if (!myPositionMarker) {
          makeMyPositionMarker(latitude || 0, longitude || 0, map);
        }
      }
    }
  }, [map, filteredBrandList, isAreaSelected]);

  // 좌표 정보가 바뀌면 맵을 다시 그리는 역할
  useEffect(() => {
    const {longitude, latitude} = coordinates;
    if (longitude && latitude) {
      const _latitude = movedCoordinates.latitude || latitude;
      const _longitude = movedCoordinates.longitude || longitude;
      setIsRenderComplete(false);
      if (map) {
        changeMapPosition(_latitude, _longitude);
        changeBoundary();
        mapCenterChangeRef.current = centerChangeListener().append(map);
      } else {
        makeMap(_latitude, _longitude);
      }
      reverseGeocode(_latitude, _longitude);
      setIsRenderComplete(true);
      reLoadBrandRef.current = false;
    }
  }, [coordinates, map]);

  // 맵이 그려지고, 퍼미션이 확인되면 현재위치 혹은 고정위치로 옮기는 역할
  useEffect(() => {
    if (mapElement.current) {
      if (permissionState?.coarseLocation === 'granted') {
        if (!isAreaSelected) {
          loadLocationInfo();
          initMap();
        }
      } else if (permissionState?.coarseLocation === 'denied') {
        setCoordinates &&
          setCoordinates({
            latitude: 37.547222,
            longitude: 127.047306,
            error: null,
          });
        setIsAreaSelected(true);
        // 위치정보 사용 허가 요청
        requestPostionAccessAllowPop();
        initMap();
      }
    }
  }, [mapElement, permissionState]);

  // 브랜드 리스트 화면이 아닐경우 scroll 동작 x
  useEffect(() => {
    if (height === BRAND_LIST_MAX_HEIGHT) {
      setIsScrollLock(false);
    } else if (height <= INITIAL_LIST_HEIGHT) {
      setIsScrollLock(true);
    }
  }, [height]);

  useEffect(() => {
    // 초기 진입시 부모 컨테이너 높이 계산
    if (panelRef.current) {
      const container = panelRef.current.parentElement;
      if (container) {
        setContainerHeight(container.offsetHeight);
      }
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.id === -1) {
      setIsOpenLoginAlertModal(true);
    }
  }, [userInfo]);

  return (
    <Layout.Main>
      <Header
        title={
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              {!isAreaSelected && (
                <div className={styles.currentPosWrapper}>
                  <span className={styles.currentPosition}>현위치</span>
                </div>
              )}
              <div
                onClick={() =>
                  accessToken ? setIsOpen(true) : setIsOpenModal(true)
                }>
                <span className={styles.addr2} style={{marginRight: '5px'}}>
                  {addr2Str}
                </span>
                <span className={styles.addr3}>{addr3Str}</span>
              </div>
            </div>
          </div>
        }
        isCommunity
        isWhite
        isNoRing
        hasDropdown
        isRealtime={isActiveCurrentPosition}
      />
      <div style={{minHeight: `calc(100vh - ${extraHeight})`}}>
        <div style={{position: 'relative'}}>
          <div
            ref={mapElement}
            id="map"
            style={{
              height: `calc(100vh - ${extraHeight})`,
              visibility: `${
                height === BRAND_LIST_MAX_HEIGHT ? 'hidden' : 'inherit'
              }`,
            }}>
            {isShowReload && (
              <button
                className={styles.refreshBtn}
                onClick={() => {
                  setIsAreaSelected && setIsAreaSelected(true);
                  setHeight(INITIAL_LIST_HEIGHT);
                  setIsRenderComplete(false);
                  changeCoordinates();
                  reLoadBrandRef.current = true;
                }}
                style={{
                  bottom:
                    height < INITIAL_LIST_HEIGHT
                      ? '96px'
                      : `${INITIAL_LIST_HEIGHT + 12}px`,
                }}>
                <RefreshIcon />
                <span>이 지역에서 검색</span>
              </button>
            )}
            <button
              className={styles.currentPosBtn}
              onClick={async () => {
                if (permissionState?.coarseLocation === 'denied') {
                  requestPostionAccessAllowPop();
                } else if (permissionState?.coarseLocation === 'granted') {
                  setMovedCoordinates({
                    latitude: null,
                    longitude: null,
                    error: null,
                  });
                  loadLocationInfo();
                  setIsAreaSelected && setIsAreaSelected(false);
                  setHeight(INITIAL_LIST_HEIGHT);
                  setIsRenderComplete(false);
                  setIsShowReload(() => false);
                  centerChangeListener().remove();
                  reLoadBrandRef.current = true;
                }
              }}
              style={{
                bottom:
                  height < INITIAL_LIST_HEIGHT
                    ? '96px'
                    : `${INITIAL_LIST_HEIGHT + 12}px`,
              }}>
              <CurrentPositionIcon />
            </button>
          </div>
        </div>
        <motion.div
          className={styles.brandListWrapper}
          ref={panelRef}
          style={{height}}
          drag={selectedBrand ? false : 'y'}
          dragConstraints={{
            top: containerHeight - BRAND_LIST_MAX_HEIGHT,
            bottom: 0,
          }}
          dragElastic={0.2}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          initial={{y: 0}}>
          {height !== BRAND_LIST_MAX_HEIGHT && !selectedBrand && (
            <div className={styles.top}>
              <div className={styles.grap}></div>
            </div>
          )}
          {isRenderComplete && brands && (
            <BrandList
              items={brands}
              height={height}
              isMaxHeight={height === BRAND_LIST_MAX_HEIGHT}
              selectedBrand={selectedBrand}
              goToMap={() => setHeight(INITIAL_LIST_HEIGHT)}
              isScrollLock={isScrollLock}
              scrollRef={scrollRef}
              setSelectedBrand={setSelectedBrand}
              resetAllMarkers={resetAllMarkers}
            />
          )}
          {/* isLoading: 브랜드데이터, isRenderComplete: 지도 랜더링 완성 */}
          {(!isRenderComplete || isLoading) && <LocationLoader />}
        </motion.div>
      </div>
      <Layout.BottomNavigation />
      <AddressSelector
        addressList={addressList}
        updateAddress={updateAddress}
        setIsGeolocationModalOpen={setIsGeolocationModalOpen}
        setIsLoad={setIsLoad}
      />
      <BrandLoginModal
        isOpen={isOpenModal}
        setIsOpen={(is: boolean) => setIsOpenModal(is)}
        action={() => navigator('/login')}
      />
      <GeolocationModal
        isOpen={isGeolocationModalOpen as boolean}
        setIsOpen={setIsGeolocationModalOpen as (is: boolean) => void}
        action={async () => {
          setIsGeolocationModalOpen && setIsGeolocationModalOpen(false);
          await Echo.echo({
            value: 'geolocation',
          });
        }}
      />
      <LoginAlertModal
        isOpen={isOpenLoginAlertModal}
        onClose={() => setIsOpenLoginAlertModal(false)}
      />
    </Layout.Main>
  );
}
