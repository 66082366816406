import {Preferences} from '@capacitor/preferences';
import {Capacitor} from '@capacitor/core';

export default function useStorage() {
  const setItem = async (key: string, value: string) => {
    await Preferences.set({
      key: key,
      value: value,
    });
  };
  const getItem = async (key: string) => {
    const {value} = await Preferences.get({
      key: key,
    });
    return value || '';
  };
  const setToken = async (token: string) => {
    try {
      await Preferences.set({
        key: 'knewnew_t_k',
        value: token,
      });
    } catch (e) {
      console.log(`Preference222:`, e);
    }
  };
  const getToken = async () => {
    const value = (await Preferences.get({key: 'knewnew_t_k'})).value || '';
    return value;
  };
  const removeToken = async () => {
    await Preferences.remove({key: 'knewnew_t_k'});
  };
  const readPush = async (email: string, id: number) => {
    if (Capacitor.getPlatform() !== 'web') {
      await Preferences.set({
        key: `${email}_push_${id}`,
        value: 'read',
      });
    }
  };
  const getPush = async (email: string, id: number) => {
    const val = await Preferences.get({
      key: `${email}_push_${id}`,
    });
    console.log(val);
    return val.value;
  };
  const setLoginProvider = async (provider: string) => {
    const key = 'knewnew_login_provider';
    await Preferences.set({
      key,
      value: provider,
    });
  };
  const getLoginProvider = async () => {
    const key = 'knewnew_login_provider';
    const provider =
      (
        await Preferences.get({
          key,
        })
      ).value || '';
    console.log(provider);
    return provider;
  };

  return {
    setItem,
    getItem,
    setToken,
    getToken,
    removeToken,
    readPush,
    getPush,
    setLoginProvider,
    getLoginProvider,
  };
}
