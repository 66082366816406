import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {QueryKey, UseQueryOptions} from '@tanstack/react-query';
import {useRecoilValue} from 'recoil';

import {useGetCurationList} from '@/hooks/queries/brands';
import Layout from '@/layouts';
import OverlayHeader from '@/layouts/OverlayHeader';
import BrandShareBottomSheet from '@/components/brandShareBottomSheet';
import {user, UserInfo} from '@/store';
import {likeBrand} from '@/services/brands';

import {CourseBrandItem} from '../search/components/CourseList/CourseList';
import styles from './styles.module.scss';
import LoginRequest from '../search/components/LoginRequest';

export interface CurationData {
  brandList: CourseBrandItem[];
  id: number;
  title: string;
  subtitle: string;
  type: string;
  image: string;
}

function BrandItem({
  item,
  idx,
  toggleLike,
}: {
  item: CourseBrandItem;
  idx: number;
  toggleLike: (idx: number, isLike: boolean) => void;
}) {
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const navigator = useNavigate();
  const _doBookmark = async ($event: React.MouseEvent<HTMLDivElement>) => {
    $event.preventDefault();
    $event.stopPropagation();
    try {
      if (userInfo.id == -1) {
        navigator('/login', {
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
        });
      } else {
        const id = item.parentId;
        const next = !item.is_bookmark;
        await likeBrand(accessToken, id, next);
        toggleLike(idx, next);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div
      className={styles.item_wrapper}
      onClick={() => navigator(`/brand/${item.parentId}/${item.id}`)}>
      <div className={styles.img_wrapper}>
        <img src={item.collectionImage || item.imageList[0]} />
        <div className={styles.like_wrapper} onClick={_doBookmark}>
          {!item.is_bookmark ? (
            <span
              className={`icon ${styles.heart_empty} active ${styles.heart_icon}`}>
              &nbsp;
            </span>
          ) : (
            <span
              className={`icon ${styles.heart_filled} ${styles.heart_icon}`}>
              &nbsp;
            </span>
          )}
        </div>
      </div>
      <div className={styles.txt_wrapper}>
        <h3>{item.title}</h3>
        <p>{item.keyword}</p>
      </div>
    </div>
  );
}

export default function Curation() {
  const navigator = useNavigate();
  const {id} = useParams<{id: string}>();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpenShareSheet, setIsOpenShareSheet] = useState(false);
  const [hasLocked, setHasLocked] = useState(false);
  const [brandList, setBrandList] = useState<CourseBrandItem[]>([]);
  const userInfo = useRecoilValue(UserInfo);
  const toggleLike = (idx: number, isLike: boolean) => {
    const _items = [...brandList];
    _items[idx].is_bookmark = isLike;
    setBrandList(_items);
  };
  const onScroll = () => {
    if (window.scrollY > 10) {
      setIsScrolled(() => true);
    } else {
      setIsScrolled(() => false);
    }
    if (window.scrollY > 1000 && !hasLocked && userInfo.id === -1) {
      setHasLocked(() => true);
      document.body.style.overflow = 'hidden';
    }
  };
  const {data, isLoading} = useGetCurationList(
    {
      magazine_collection_id: id ? parseInt(id) : 0,
    },
    {
      enabled: !!id,
      queryKey: ['curationList', id],
    } as UseQueryOptions<CurationData, Error, CurationData, QueryKey>,
  );

  useEffect(() => {
    if (data) {
      setBrandList(data.brandList);
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Layout.Main>
      <LoginRequest hasLocked={hasLocked} isCuration={true} />
      <div>
        <OverlayHeader
          isScrolled={isScrolled}
          clickShare={() => setIsOpenShareSheet(true)}
          back={() => navigator(-1)}
        />
        <div className={styles.topImgWrapper}>
          <img src={data?.image} style={{width: '100%'}} />
        </div>
        <div className={styles.collectionWrap}>
          <div className={styles.listArea}>
            <div className={styles.list} style={{paddingBottom: '40px'}}>
              {data?.brandList.map((brand, idx) => {
                return (
                  <BrandItem
                    key={`brand_item_${idx}`}
                    idx={idx}
                    item={brand}
                    toggleLike={toggleLike}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <BrandShareBottomSheet
          isOpen={isOpenShareSheet}
          setIsOpen={setIsOpenShareSheet}
          item={{
            title: '',
            info: '',
            imageList: [''],
          }}
        />
      </div>
    </Layout.Main>
  );
}
