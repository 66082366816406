import Axios from 'axios';

import {errorInterceptor} from '../utils';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use(undefined, errorInterceptor.bind(this));

// 홈 브랜드 내역
export const getBrandsInHome = (
  token: string | undefined,
  limit: number,
  offset: number,
) => {
  return token
    ? axios.get('/magazine/brands/list/area', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit,
          offset,
        },
      })
    : axios.get('/magazine/brands/list/area', {
        params: {
          limit,
          offset,
        },
      });
};

// 브랜드 리스트
export const getBrands = (
  token: string | undefined,
  params: BrandListParam,
) => {
  const path = '/magazine/brands/list/';

  return token
    ? axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
    : axios.get(path, {
        params,
      });
};

export const getNewBrands = (
  token: string | undefined,
  params: BrandListParam,
) => {
  const path = '/magazine/brands/collection/list/';
  return token
    ? axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...params,
        },
      })
    : axios.get(path, {
        params: {
          ...params,
        },
      });
};

export const likeBrand = (
  token: string,
  parentId: number,
  isBookmark: boolean,
) => {
  const path = `/magazine/brands/group/bookmark/${parentId}/`;
  return axios.post(
    path,
    {
      isBookmark,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const requestOpen = (token: string, brand_id: number) => {
  const path = `/magazine/brands/${brand_id}/requestopen/`;
  return axios.post(path, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSpeicalBrandBanners = () => {
  const path = '/banner/?type=brand_special';
  return axios.get(path);
};

export const getQuickMenus = () => {
  const path = '/banner/?type=quick_menu';
  return axios.get(path);
};

export const getBrandInfo = (token: string, id: number) => {
  const path = `/magazine/brands/group/${id}/`;
  return token
    ? axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : axios.get(path);
};

export const getBrandBenefits = (token: string, params: BrandBenefitParams) => {
  const path = '/magazine/product/';
  return token
    ? axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
    : axios.get(path, {
        params,
      });
};

export const getCommunityByBrands = (
  token: string,
  params: CommunityByBrandParam,
) => {
  const path = '/review/';
  return token
    ? axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
    : axios.get(path, {
        params,
      });
};

export const getBrandListArea = (
  token: string,
  brandId: number,
  params: BrandListAreaParam,
) => {
  const path = `/magazine/brands/${brandId}/related/`;
  return token
    ? axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
    : axios.get(path, {
        params,
      });
};

export const getBrandsCategories = (token?: string) => {
  const path = '/magazine/brands/categories';
  return token
    ? axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : axios.get(path);
};

export const getFamousAreas = () => {
  const path = '/review/address/?type=famous&limit=20';
  return axios.get(path);
};

export const getBrandMagazine = (id: number) => {
  const path = `/magazine/?brand=${id}`;
  return axios.get(path);
};

export const getBanners = () => {
  const path = `/banner/?type=home_1`;
  return axios.get(path);
};
export const getCourseBanners = () => {
  const path = `/banner/?type=course`;
  return axios.get(path);
};

export const getSubCuration = () => {
  const path = `/magazine/brands/collection/list/?image=true&type=home_curation`;
  return axios.get(path);
};

export const getCourseList = () => {
  const path = `/magazine/brands/collection/list/?image=true&type=course`;
  return axios.get(path);
};

export const getCurationList = (magazine_collection_id: number) => {
  const path = `/magazine/brands/collection/info/${magazine_collection_id}`;
  return axios.get(path);
};
