import React, {useEffect, useState, useRef, useMemo} from 'react';
// eslint-disable-next-line import/order
import {Calendar} from 'react-calendar';

import 'react-calendar/dist/Calendar.css'; // 기본 스타일 추가
import './calendarStyles.scss';
import {Minus, Plus, ChevronDown} from 'react-feather';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {Value} from 'react-calendar/dist/cjs/shared/types';

import {addComma} from '@/utils';
import useLogger, {Action} from '@/hooks/useLogger';
import {getDateOptions} from '@/services/magazine';
import {isOpenSoldOutModal, SoldOutModalInfo, user} from '@/store';

import styles from './styles.module.scss';
import Options from '../ProductOptionSelector/Options';
import {ProductOptions} from '../ProductOptionSelector/ProductOptionSelector';

interface Props {
  isOpen: boolean;
  togglePopup: (is: boolean) => void;
  magazine: MagazineItem;
  magazineId: number;
  buyBenefit?: (selectedOptionId: number, options?: string[]) => void;
  buyBtnTxt?: string;
}

const CalendarOptions = ({
  isOpen,
  togglePopup,
  magazine,
  magazineId,
}: Props) => {
  const navigator = useNavigate();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [isOpenOption1, setIsOpenOption1] = useState(false);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [selectedOptionId, setSelectedOptionId] = useState<number>();
  const [selectedItem, setSelectedItem] = useState<ProductOptions>();
  //   const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [, setHasCountError] = useState(false);
  const [count, setCount] = useState(1);
  const [amount, setAmount] = useState(0);
  const [options, setOptions] = useState<PopupOptions[]>([]);
  const accessToken = useRecoilValue(user);
  const setSoldoutType = useSetRecoilState(SoldOutModalInfo);
  const setSoldoutModal = useSetRecoilState(isOpenSoldOutModal);
  const [selectedDateOptions, setSelectedDateOptions] = useState<
    ProductOptions[]
  >([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const sender = useLogger();
  const isDisabledButton = useMemo(() => {
    return !selectedDate || !selectedItem || from.length == 0 || to.length == 0;
  }, [selectedDate, selectedItem, from, to]);

  const option1PH = selectedDate
    ? '옵션을 선택해주세요'
    : '날짜를 선택해주세요';
  const maxCount = 10;
  const limitPerUser = 5;
  const toggleItem = (type: string, item: ProductOptions) => {
    setSelectedItem(item);
    setAmount(item.amount || 0);
    setSelectedOptionId(item.id);
  };

  const openOption1 = (value: boolean) => {
    setIsOpenOption1(value);
  };

  const clickOption1EmptyNotice = () => {
    // 더미 클릭 핸들러
  };
  const updateCount = (type: 'plus' | 'minus') => {
    switch (type) {
      case 'plus':
        if (limitPerUser > count) {
          setCount(count + 1);
        }
        // if (maxCount !== limitPerUser) {
        if (count + 1 >= limitPerUser) {
          setHasCountError(true);
        }
        // }
        break;
      case 'minus':
        setCount(count === 1 ? count : count - 1);
        setHasCountError(false);
        break;
    }
  };

  const getOption = (
    id: number,
    options: PopupOptions[],
  ): PopupOptions | null => {
    let option = null;
    for (let i = 0, max = options.length; i < max; i++) {
      const opt = options[i];
      if (opt.id === id) {
        option = opt;
        break;
      } else {
        option = opt.child ? getOption(id, opt.child) : null;
      }
    }
    return option;
  };
  // 날짜 비활성화 함수
  const tileDisabled = ({date, view}: {date: Date; view: string}) => {
    // month view에서만 적용
    if (view === 'month') {
      // availableDates에 없는 날짜는 비활성화
      return !availableDates.find(
        availableDate =>
          availableDate.getDate() === date.getDate() &&
          availableDate.getMonth() === date.getMonth() &&
          availableDate.getFullYear() === date.getFullYear(),
      );
    }
    return false;
  };
  // 날짜 선택 핸들러 타입 수정
  const handleDateChange = (value: Value) => {
    if (value instanceof Date) {
      setSelectedDate(value);
      console.log('선택된 날짜:', formatDate(value));
    }
  };

  const moveToOrder = () => {
    /*if (magazine.isBought) {
      setSoldoutType('popup_max');
      setSoldoutModal(true);
      return;
    }*/

    sender({
      _msg: 'buy_click2',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });

    getDateOptions(from, to, magazine.id, accessToken).then(
      (data: PopupOptions[]) => {
        const selectedOption = getOption(
          selectedOptionId as number,
          data as PopupOptions[],
        );
        if (selectedOption) {
          const isSoldout =
            (selectedOption as PopupOptions).limit -
              (selectedOption as PopupOptions).soldCount ===
            0;
          if (isSoldout) {
            setSoldoutType('option');
            setSoldoutModal(true);
            return;
          }
        }
        navigator(`/order/detail/${magazineId}`, {
          state: {
            magazine,
            selectedDate: {key: formatDate(selectedDate || new Date())},
            selectedTime: {key: selectedItem?.key || ''},
            amount: count,
            price: amount,
            option: selectedOptionId,
          },
        });
      },
    );
  };
  // 날짜를 'YYYY-MM-DD' 형식으로 변환하는 함수
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleClickOutside = (event: any) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
      togglePopup(false);
    }
  };

  const initSelectedState = () => {
    setSelectedItem(undefined);
    setSelectedOptionId(undefined);
    setCount(1);
    setAmount(0);
  };

  const getOptions = async () => {
    const today = new Date();
    const firstDay = `${today.getFullYear()}-${String(
      today.getMonth() + 1,
    ).padStart(2, '0')}-01`;
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastDayStr = `${lastDay.getFullYear()}-${String(
      lastDay.getMonth() + 1,
    ).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;
    setFrom(firstDay);
    setTo(lastDayStr);

    getDateOptions(firstDay, lastDayStr, magazineId, accessToken).then(
      (data: PopupOptions[]) => setOptions(data),
    );
  };

  const onMonthChange = async (date: Date) => {
    const firstDay = `${date.getFullYear()}-${String(
      date.getMonth() + 1,
    ).padStart(2, '0')}-01`;
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastDayStr = `${lastDay.getFullYear()}-${String(
      lastDay.getMonth() + 1,
    ).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;
    setFrom(firstDay);
    setTo(lastDayStr);

    getDateOptions(firstDay, lastDayStr, magazineId, accessToken).then(
      (data: PopupOptions[]) => setOptions(data),
    );
  };

  useEffect(() => {
    if (options) {
      setAvailableDates(
        options.map(option => new Date(option.dateValue || '')),
      );
    }
  }, [options]);

  useEffect(() => {
    if (selectedDate) {
      const selectedDateOptions = options?.find(
        o => o.dateValue === formatDate(selectedDate),
      );
      initSelectedState();
      setSelectedDateOptions(
        selectedDateOptions?.child.map(c => {
          return {
            key: c.value,
            value: c.id.toString(),
            id: c.id,
            isSoldout: c.limit - c.soldCount === 0,
            amount: c.amount,
          };
        }) || [],
      );
    }
  }, [selectedDate]);

  useEffect(() => {
    getOptions();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="modal-wrapper" style={{display: isOpen ? 'flex' : 'none'}}>
      <div
        className={`modal-contents category ${isOpen ? 'active' : 'none'}`}
        ref={wrapperRef}>
        <div className={`filter-modal-header`}>
          <ChevronDown
            size={'30px'}
            color={'#D2D0D5'}
            style={{cursor: 'pointer'}}
            onClick={() => togglePopup(false)}
          />
        </div>
        <Calendar
          calendarType="gregory"
          formatDay={(locale, date) => date.getDate().toString()}
          tileDisabled={tileDisabled}
          onChange={handleDateChange}
          onActiveStartDateChange={({activeStartDate}) => {
            if (activeStartDate) {
              setOptions([]);
              onMonthChange(activeStartDate);
            }
          }}
        />
        <div className={styles.options_wrapper}>
          <Options
            type={'date'}
            toggle={toggleItem}
            placeholder={option1PH}
            optionList={selectedDateOptions}
            disabled={false}
            selectedValue={selectedItem?.key || ''}
            isOpenList={isOpenOption1}
            setIsOpenList={openOption1}
            isShowSoldoutNotice={false}
            onClickEmptyNotice={clickOption1EmptyNotice}
          />
        </div>
        <div className={styles.price_wrapper}>
          <span className={styles.price}>{`${addComma(
            amount * count,
          )}원`}</span>
          <span className={styles.counter}>
            <Minus
              size={'16px'}
              color={count === 1 ? '#D2D0D5' : '#000'}
              style={count != 1 ? {cursor: 'pointer'} : {}}
              onClick={() => updateCount('minus')}
            />
            <div className={styles.count}>{count}</div>
            <Plus
              size={'16px'}
              color={count === maxCount ? '#D2D0D5' : '#000'}
              style={count != maxCount ? {cursor: 'pointer'} : {}}
              onClick={() => {
                updateCount('plus');
              }}
            />
          </span>
        </div>

        <div className={`filter-modal-footer ${styles.modal_footer}`}>
          <button
            className={`modal btn black-filled ${
              isDisabledButton ? styles.disable_button : ''
            }`}
            style={{
              marginBottom: Capacitor.getPlatform() === 'ios' ? '10px' : '0',
            }}
            disabled={isDisabledButton}
            onClick={() => moveToOrder()}>
            {'구매하기'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarOptions;
