import axios, {InternalAxiosRequestConfig, RawAxiosRequestHeaders} from 'axios';
import _ from 'lodash';
import qs from 'qs';
import {Preferences} from '@capacitor/preferences';

import {errorInterceptor} from '@/utils';

const AXIOS_DEFAULT_OPTION = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
  },
  timeout: 1000 * 60 * 5,
  withCredentials: true,
  interceptable: false,
  showLoadingScreen: true,
};

const AxiosInstance = Object.assign(axios.create(AXIOS_DEFAULT_OPTION), {
  requestCount: 0,

  decreaseRequest() {
    this.requestCount -= 1;

    if (this.requestCount < 0) {
      this.requestCount = 0;
    }
  },

  getCount() {
    return this.requestCount;
  },

  increaceRequest() {
    this.requestCount += 1;
  },
});

AxiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    const TOKEN_KEY = 'knewnew_t_k';

    // Capacitor Storage에서 토큰 가져오기
    const {value: accessToken} = await Preferences.get({key: TOKEN_KEY});
    if (accessToken) {
      (
        config.headers as RawAxiosRequestHeaders
      ).Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
);

axios.interceptors.response.use(undefined, errorInterceptor.bind(this));

const ApiWrapper = {
  paramsSerializer: (params: object) => {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    });
  },
  get: async (api: string, option = {params: {}}) => {
    let newOption = option;

    // reference: https://github.com/axios/axios#request-config
    _.forEach(newOption.params, value => {
      if (_.isArray(value)) {
        newOption = _.assign({}, newOption, {
          paramsSerializer: {
            serialize: (params: object) => ApiWrapper.paramsSerializer(params),
          },
        });
      }
    });

    return AxiosInstance.get(api, newOption);
  },

  delete: (api: string, option = {}) => {
    return AxiosInstance.delete(api, option);
  },

  post: (api: string, data = {}, option = {}) => {
    return AxiosInstance.post(api, data, option);
  },

  put: (api: string, data = {}, option = {}) => {
    return AxiosInstance.put(api, data, option);
  },
};

export default ApiWrapper;
