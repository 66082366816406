import React, {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {useLocation, useNavigate} from 'react-router-dom';
import {SafeArea} from '@aashu-dubey/capacitor-statusbar-safe-area';
import {Capacitor} from '@capacitor/core';
import {Browser} from '@capacitor/browser';

import {isMobile} from '@/utils';
import {PathHistories} from '@/store';

import styles from './styles.module.scss';
interface Props {
  isScrolled?: boolean;
  title?: string;
  type?: 'collection' | 'brand';
  back?: () => void;
  clickShare?: () => void;
  noBack?: boolean;
}

export default function OverlayHeader({
  isScrolled,
  title,
  type,
  back,
  clickShare,
  noBack,
}: Props) {
  const navigator = useNavigate();
  const location = useLocation();
  const [histories, setHistories] = useRecoilState(PathHistories);
  const historyBack = () => {
    if (back) {
      return back();
    }
    const _histories = JSON.parse(JSON.stringify(histories));
    _histories.splice(-1, 1);
    if (_histories.length > 0) {
      const {path, state} = _histories[_histories.length - 1];
      setHistories(_histories);
      setTimeout(() => {
        navigator(`/${path}`, {
          state: {
            ...state,
            scrollEnabled: location.state?.scrollEnabled,
          },
        });
      }, 0);
    } else {
      navigator('/home');
    }
  };

  const openBrowser = async () => {
    if (Capacitor.getPlatform() === 'web') {
      window.open('http://pf.kakao.com/_qmUqG/chat', '_blank');
    } else {
      await Browser.open({
        url: 'http://pf.kakao.com/_qmUqG/chat',
      });
    }
  };
  const getStatusBarHeight = async () => {
    const {height} = await SafeArea.getStatusBarHeight();
    return height; // Ex. 29.090909957885742
  };
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      getStatusBarHeight();
    }
  }, []);
  return (
    <div
      className={`${styles.contentHeader} ${
        isScrolled ? styles.scrolled : ''
      } ${isMobile() ? styles.mobile : ''} ${
        Capacitor.getPlatform() === 'android' ? styles.android : ''
      }`}>
      <div
        className={noBack ? styles.noBack : styles.back}
        onClick={() => (noBack ? null : historyBack())}>
        {!noBack && <span className={styles.backIcon}></span>}
      </div>
      {isScrolled && <h3>{title}</h3>}

      {!!clickShare && (
        <div style={{display: 'flex'}}>
          <div className={styles.share} onClick={() => clickShare()}>
            <span className={styles.shareIcon}></span>
          </div>
        </div>
      )}
      {type === 'collection' && (
        <div style={{display: 'flex'}}>
          <div
            className={`${styles.back} ${styles.kakao}`}
            style={{padding: '0', marginRight: isScrolled ? '0' : '8px'}}
            onClick={() => openBrowser()}>
            {isScrolled ? (
              <span className={styles.kakaoWrap}>
                <span className={styles.kakaoIcon}></span>
                <span className={styles.kakaoTxt}>문의</span>
              </span>
            ) : (
              <span className={styles.kakaoIcon}></span>
            )}
          </div>
          <div
            className={styles.back}
            style={{padding: '0'}}
            onClick={() => navigator('/home')}>
            <span className={styles.homeIcon}></span>
          </div>
        </div>
      )}
    </div>
  );
}
