import React from 'react';
import {useNavigate} from 'react-router-dom';

import Modal from '@/layouts/Modal';

import styles from './styles.module.scss';
import Button from '../Button';

interface LoginAlertModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LoginAlertModal({isOpen}: LoginAlertModalProps) {
  const navigator = useNavigate();
  return (
    <Modal.Alert
      width={'315px'}
      height={'255px'}
      isOpen={isOpen}
      style={{
        borderRadius: '10px',
        padding: '40px 16px 10px 16px',
      }}>
      <div className={styles.contents} style={{padding: '0'}}>
        <div className={styles.txtArea}>
          <div>
            지금 로그인하고
            <br />
            F&B 브랜드들을 확인하세요
          </div>
        </div>
        <div className={styles.buttonArea}>
          <Button.ModalButton
            width={'100%'}
            height={'50px'}
            txt={'로그인'}
            background={'#000'}
            color={'#fff'}
            onClick={() => navigator('/login')}
          />
          <Button.ModalButton
            width={'100%'}
            height={'50px'}
            txt={'홈으로'}
            background={'#fff'}
            color={'#9F9CA3'}
            onClick={() => navigator('/home')}
          />
        </div>
      </div>
    </Modal.Alert>
  );
}
