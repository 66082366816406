import {useCallback, useEffect, useState} from 'react';
import {FirebaseAuthentication} from '@capacitor-firebase/authentication';
import {Capacitor} from '@capacitor/core';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {Auth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import {AxiosError} from 'axios';

import {
  duplicatedEmail,
  duplicatedProviderType,
  SnsToken,
  user,
  UserInfo,
} from '../store/user';
import useFirebase from './useFirebase';
import {login, userDetail} from '../services/user';
import useNative from './useNative';
import useStorage from './useStorage';
import {
  AuthRootPath,
  IsOpenCertTryError,
  LoginPrevPath,
  ToastMessage,
  isOpenDuplicatedEmailModal,
  isOpenToast,
} from '../store';
import {getCertTryCnt} from '../services/coupon';
import {isMobile} from '../utils';

export default function useAuth() {
  const {firebaseApp, initAnalytics, auth: firebaseAuth} = useFirebase();
  const {setToken, setLoginProvider} = useStorage();
  const navigator = useNavigate();
  const {Echo} = useNative();
  const [naverLoginInstance, setNaverLoginInstance] = useState();
  const loginPrevPath = useRecoilValue(LoginPrevPath);
  const setUserInfo = useSetRecoilState(UserInfo);
  const setSnsToken = useSetRecoilState(SnsToken);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const accessToken = useRecoilValue(user);
  const setAccessToken = useSetRecoilState(user);
  const setOpenDuplicatedModal = useSetRecoilState(isOpenDuplicatedEmailModal);
  const setDuplicatedEmail = useSetRecoilState(duplicatedEmail);
  const setDuplicatedProviderType = useSetRecoilState(duplicatedProviderType);
  const setAuthRootPath = useSetRecoilState(AuthRootPath);
  const setIsOpenCertTryError = useSetRecoilState(IsOpenCertTryError);
  const localStorageAuthKey = 'CERT_ROOT_PATH';
  const getCurrentUser = async () => {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result.user;
  };

  const initNaver = async () => {
    const naver = (window as any).naver;
    const loginInstance = new naver.LoginWithNaverId({
      clientId: 'i1hnd0_tYKgCfA29HRFB',
      callbackUrl:
        'http://' +
        window.location.hostname +
        (location.port == '' || location.port == undefined
          ? ''
          : ':' + location.port) +
        '/auth/naver',
      isPopup: true,
      loginButton: {color: 'green', type: 3, height: 60},
    });
    setNaverLoginInstance(loginInstance);
  };

  const signInWithNaverWeb = async () => {
    const url = (naverLoginInstance as any)?.generateAuthorizeUrl() as string;
    window.open(url, '_self');
  };

  const signInWithGoogleWeb = useCallback(async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(firebaseAuth as Auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      let token;
      if (credential) {
        token = credential.accessToken;
        await setSnsToken(token as string);
      }
      // The signed-in user info.
      const user = result.user;
      return {
        user,
        token,
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }, [firebaseAuth]);

  const signInWithGoogleMobile = async () => {
    try {
      const res = await FirebaseAuthentication.signInWithGoogle();
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        console.log('no currentUser');
        throw new Error('no currentUser');
      }
      await setSnsToken(res.credential?.accessToken as string);
      return {
        user: currentUser,
        token: res.credential?.accessToken as string,
      };
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const signInWithAppleMobile = async () => {
    try {
      const res = await FirebaseAuthentication.signInWithApple();
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        throw new Error('no currentUser');
      }
      await setSnsToken(res.credential?.authorizationCode as string);
      return {
        user: currentUser,
        token: res.credential?.authorizationCode,
      };
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const signInWithGoogle = async () => {
    try {
      let res;
      if (Capacitor.getPlatform() === 'web') {
        res = await signInWithGoogleWeb();
      } else {
        res = await signInWithGoogleMobile();
      }
      // return checkExisteEmail(res.user.email as string, res.token as string);
      return loginBySns(res.token as string, 'google');
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const appendAuthCallbackFn = () => {
    const script = document.createElement('script');
    const callBackFunc = document.createTextNode(
      'function result(result) {' +
        'try {' +
        'result = JSON.parse(result);' +
        'var queryParam = new URLSearchParams(result).toString();' +
        "window.location.href='/user/auth/process?' + queryParam" +
        '} catch (error) {' +
        'console.log(error);' +
        '}' +
        '}',
    );
    script.appendChild(callBackFunc);
    document.body.appendChild(script);
  };

  const signInWithApple = async () => {
    try {
      const res = await signInWithAppleMobile();
      return loginBySns(res.token as string, 'apple');
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const loginBySns = async (
    token: string,
    providerType: 'kakao' | 'email' | 'naver' | 'apple' | 'google',
  ) => {
    try {
      if (token) {
        const res = await login(token as string, providerType);
        await setLoginProvider(providerType);
        if (!res.data.accessToken) {
          let obj = {...res.data} as UserInfoObj;
          obj = {
            ...obj,
            nickname: obj.nickname.slice(0, 15),
          };
          setUserInfo(obj);
          navigator('/signup');
        } else {
          setAccessToken(res.data.accessToken);
          await setToken(res.data.accessToken);
          const userInfoRes = await userDetail(res.data.accessToken as string);
          setUserInfo(userInfoRes.data as UserInfoObj);
          navigator(loginPrevPath ? loginPrevPath : '/home');
        }
      }
    } catch (e) {
      const code = (e as AxiosError).response?.status;
      const existedProviderType = ((e as AxiosError).response?.data as any)
        .providerType as 'kakao' | 'naver' | 'google' | 'apple' | 'email';
      if (code === 600) {
        setDuplicatedProviderType(existedProviderType);
        setDuplicatedEmail(((e as AxiosError).response?.data as any).email);
        setOpenDuplicatedModal(true);
        // loginWithToken(token, e.response.data.providerType);
      }
    }
  };

  const loginWithKakaoMobile = async () => {
    try {
      const res = await Echo.echo({value: 'kakaoLogin'});
      if (res.result && res.result === 'notInstalled') {
        setToastMessage('카카오톡 앱 설치 후 로그인해주십시오.');
        setIsOpenToast(true);
      } else {
        const token = res.accessToken as string;
        setSnsToken(token);
        loginBySns(token, 'kakao');
      }
    } catch (e: any) {
      if (e.response.status === 600) {
        // loginWithToken(token, e.response.data.providerType);
      }
    }
  };

  const openAuth = async (path: string, states?: any) => {
    setAuthRootPath(path);
    const tryRes = await getCertTryCnt(accessToken);
    const browserType = Capacitor.getPlatform() === 'web' ? 'WB' : 'HY';
    let url = !isMobile()
      ? `https://${
          process.env.REACT_APP_ENV !== 'development' ? 'cert' : 'devcert'
        }.knewnew.co.kr/mok/mok_std_request`
      : `https://${
          process.env.REACT_APP_ENV !== 'development' ? 'cert' : 'devcert'
        }.knewnew.co.kr/mok/mobile/mok_std_request`;

    url =
      Capacitor.getPlatform() === 'web' && isMobile()
        ? `${url}?type=web&link=${location.pathname.replace('/', '')}`
        : `${url}`;
    if (tryRes.data.identifyCount < 10) {
      localStorage.setItem(localStorageAuthKey, path);
      if (Capacitor.getPlatform() === 'web') {
        const type =
          Capacitor.getPlatform() === 'web'
            ? !isMobile()
              ? 'result'
              : ''
            : '';
        (window as any).MOBILEOK.process(url, browserType, type);
      } else {
        const res = await Echo.echo({
          value: `cert*${
            process.env.REACT_APP_ENV !== 'development' ? 'prod' : 'develop'
          }`,
        });
        const param = decodeURIComponent((res as any).param);
        navigator(`/user/auth/process?${param}`, {
          state: {
            selectedDate: states?.selectedDate,
            selectedTime: states?.selectedTime,
            amount: states?.amount,
            name: states?.name,
            phoneNumber: states?.phoneNumber,
            price: states?.price,
            option: states?.option,
          },
        });
        // window.location.href = `/user/auth/process?${param}`;
      }
    } else {
      setIsOpenCertTryError(true);
    }
  };

  useEffect(() => {
    if (!firebaseApp) {
      initAnalytics();
    }
    appendAuthCallbackFn();
  }, [firebaseApp]);

  return {
    signInWithGoogle,
    loginWithKakaoMobile,
    signInWithApple,
    initNaver,
    signInWithNaverWeb,
    loginBySns,
    openAuth,
    naverLoginInstance,
  };
}
