import React from 'react';

import styles from './styles.module.scss';
import useAppStore from '../../hooks/useAppStore';
import Layout from '../../layouts';

export default function RequestUpdateAndroid() {
  const {openAppStore} = useAppStore();
  return (
    <Layout.Main>
      <div className={`${styles.background}`}>
        <div className={styles.logo}></div>
        <div className={styles.update_reuqest_buttons}>
          <p className={styles.update_txt}>
            안정적인 서비스 사용을 위해
            <br />
            최신 버전으로 업데이트가 필요합니다.
          </p>
          <button className={styles.button} onClick={() => openAppStore()}>
            앱 업데이트 바로가기
          </button>
        </div>
      </div>
    </Layout.Main>
  );
}
