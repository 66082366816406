import React from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';

import {UserInfo} from '@/store';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from './styles.module.scss';
interface Props {
  hasLocked: boolean;
  isCuration?: boolean;
}

export default function LoginRequest({hasLocked, isCuration}: Props) {
  const userInfo = useRecoilValue(UserInfo);
  const sender = useLogger();
  const navigator = useNavigate();
  return (
    <div
      className={`main-content magazine-detail-wrapper ${
        hasLocked ? styles.showLogin : ''
      } ${styles.detailWrapper}`}>
      <div
        className={styles.logins}
        style={{
          display: userInfo.id === -1 ? 'flex' : 'none',
          zIndex: 3,
          position: 'relative',
        }}>
        <div className={styles.loginTxt}>
          <strong>
            단 3초만에 가입하고 <br />{' '}
            {isCuration
              ? '모든 브랜드를 확인하세요'
              : '코스 혜택을 받아보세요!'}
          </strong>

          <button
            onClick={() => {
              sender({
                _msg: 'joinmodal_click',
                _screen: window.location.pathname,
                _action: Action.CLICK,
              });
              document.body.style.overflow = 'auto';
              navigator('/login', {
                state: {
                  prevPath: `${location.pathname}${location.search}`,
                },
              });
            }}>
            로그인하고 모두 보기
          </button>
        </div>
      </div>
    </div>
  );
}
