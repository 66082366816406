/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {App} from '@capacitor/app';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination} from 'swiper/modules';

import {HasPayments, HomeBannerItem, HomeOpacity} from '@/store';
import {getHomeBanner, getPayments} from '@/services/magazine';
import {loadBannerImages} from '@/utils';
import {user} from '@/store/user';

import Fetcher from '../../common/Fetcher';
import Banner from '../Banner';
import 'swiper/css';
import styles from './styles.module.scss';

export default function BannerScroller() {
  const userAccessToken = useRecoilValue(user);
  const [bannerItems, setBannerItems] = useRecoilState(HomeBannerItem);
  const setHomeOpacity = useSetRecoilState(HomeOpacity);
  const setHasPayments = useSetRecoilState(HasPayments);
  const addBackButtonEventListener = async () => {
    await App.addListener('backButton', async () => {
      if (window.location.pathname === '/home') {
        await App.exitApp();
      }
    });
  };

  const removeBackButtonEvent = () => {
    App.removeAllListeners();
  };

  const getData = async () => {
    const res = await getHomeBanner();
    if (userAccessToken) {
      const list = await getPayments(
        {
          limit: 99999,
          offset: 0,
          valid: 'valid',
        },
        userAccessToken,
      );
      setHasPayments(list?.data.length > 0);
    } else {
      setHasPayments(false);
    }
    const banners: Banner[] = res.data;
    await loadBannerImages(banners.map(b => b.image));
    return banners;
  };

  useEffect(() => {
    addBackButtonEventListener();
    return () => removeBackButtonEvent();
  }, []);

  return (
    <Fetcher
      fetcher={getData}
      setter={setBannerItems}
      noLoader
      animationFn={() => setHomeOpacity(1)}>
      <div className={'banner-scroller'}>
        <div className="banner-wrapper">
          <div className={styles.dimmed_wrapper}></div>
          {bannerItems && bannerItems.length > 0 ? (
            <div className="banners">
              <Swiper
                initialSlide={0}
                centeredSlides={true}
                spaceBetween={0}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="homeSwiper"
                loop={true}
                slideToClickedSlide={true}
                pagination={{clickable: true}}>
                {bannerItems.map((item, idx) => {
                  return (
                    <SwiperSlide key={`slide-item-${idx}`}>
                      <Banner
                        key={`banner_item_info_${item.id}`}
                        item={item}
                        isActive={true}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Fetcher>
  );
}
