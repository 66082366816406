import React from 'react';

import Layout from '../layouts';

interface Props {
  children: React.ReactElement;
}

const Container = () => {
  return <div></div>;
};

const Home = ({children}: Props) => {
  return (
    <Layout.Main>
      <div>
        <Layout.Header isWhite isNewHome isNoRing />
        {children}
        <Layout.BottomNavigation />
      </div>
    </Layout.Main>
  );
};

Container.Home = Home;

export default Container;
