import React, {useState} from 'react';
import {Capacitor} from '@capacitor/core';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {
  BrandAddr2,
  BrandAddr3,
  BrandAreaListPage,
  isOpenAddressSelector,
  user,
} from '@/store';
import BrandLoginModal from '@/components/common/BrandLoginModal';
import useGeoLocation from '@/hooks/useGeoLocation';
import {openBrowserForLink} from '@/utils';
import useLogger, {Action} from '@/hooks/useLogger';

import Fetcher from '../../../components/common/Fetcher';
import styles from './styles.module.scss';
import {getQuickMenus} from '../../../services/brands';
export default function QuickMenu() {
  interface QuickMenu {
    id: number;
    image: string;
    type: string;
    content: string;
    isActive: boolean;
    priority: number;
    link: string;
    isInternal: boolean;
  }
  const navigator = useNavigate();
  const accessToken = useRecoilValue(user);
  const {getCurrentPosition, setIsAreaSelected} = useGeoLocation();
  const setIsOpenAddress = useSetRecoilState(isOpenAddressSelector);
  const setBrandAddr2 = useSetRecoilState(BrandAddr2);
  const setBrandAddr3 = useSetRecoilState(BrandAddr3);
  const setBrandsPage = useSetRecoilState(BrandAreaListPage);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [quickMenu, setQuickMenu] = useState<QuickMenu[]>();
  const getQuickMenu = async () => {
    const res = await getQuickMenus();
    console.log(quickMenu);
    return res.data;
  };

  const sender = useLogger();

  const openModal = () => {
    if (!accessToken) {
      setIsOpenModal(true);
    } else {
      setIsOpenAddress(true);
    }
  };

  const openLink = (link: string, isInternal: boolean) => {
    if (isInternal) {
      navigator(link);
    } else {
      if (Capacitor.getPlatform() === 'web') {
        window.open(link, '_blank');
      } else {
        openBrowserForLink(link);
      }
    }
  };

  const moveToMap = () => {
    setBrandsPage(0);
    setBrandAddr2(16);
    setBrandAddr3(undefined);
    setIsAreaSelected(false);
    getCurrentPosition();
    navigator('/map');
  };

  return (
    <Fetcher fetcher={getQuickMenu} setter={setQuickMenu}>
      <div
        className={`${styles.quick_menu} ${
          Capacitor.getPlatform() === 'android'
            ? styles.android
            : Capacitor.getPlatform() === 'ios'
            ? styles.ios
            : ''
        }`}>
        {quickMenu
          ?.filter(item => item.type == 'quick_title')
          .map((item, idx) => {
            if (idx == 0) {
              return (
                <div key={`quick_title_${idx}`} className={styles.brandTitle}>
                  {item.content}
                </div>
              );
            }
          })}
        <div className={styles.popularArea}>
          <ul className={styles.popularAreaList}>
            {quickMenu
              ?.filter(item => item.type == 'quick_menu')
              .map((item, idx) =>
                idx < 5 ? ( // Limited to 5 quick menus
                  <li
                    key={`quick_menu_${item.id}`}
                    className={styles.popularItem}
                    onClick={() => {
                      sender({
                        _msg: 'quick_menu_click' + (idx + 1),
                        _screen: window.location.pathname,
                        _action: Action.CLICK,
                        _keyword: item.content,
                        _data: item.link,
                      });

                      idx === 0
                        ? moveToMap()
                        : idx === 1
                        ? openModal()
                        : openLink(item.link, item.isInternal);
                    }}>
                    <div
                      className={`${styles.areaImg}`}
                      style={{
                        background: `url(${item.image}) no-repeat center / cover`,
                      }}></div>
                    <div className={styles.txt}>{item.content}</div>
                  </li>
                ) : (
                  <></>
                ),
              )}
          </ul>
        </div>
        <BrandLoginModal
          isOpen={isOpenModal}
          setIsOpen={(is: boolean) => setIsOpenModal(is)}
          action={() => navigator('/login')}
        />
      </div>
    </Fetcher>
  );
}
