/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useMemo, useState} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import CryptoJS from 'crypto-js';
import {Capacitor} from '@capacitor/core';
import {useRecoilState, useRecoilValue} from 'recoil';
import dayjs from 'dayjs';

import ContentsHeader from '@/layouts/ContentsHeader';
import CouponSelector from '@/components/common/CouponSelector';
import {getCouponList} from '@/services/coupon';
import {getContentsDetail} from '@/services/magazine';
import Fetcher from '@/components/common/Fetcher';
import Modal from '@/layouts/Modal';
import Button from '@/components/Button';
import QRCodePopup from '@/components/QRCodePopup';
import {
  IsBrandingMessageOnInstall,
  IsOpenQRCodePopup,
  UserInfo,
  user,
} from '@/store';
import useNative from '@/hooks/useNative';
import {
  addComma,
  generateRandomString,
  isAndroid,
  isIos,
  isMobile,
} from '@/utils';
import AddressSearchModal from '@/components/common/AddressSearchModal';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from './styles.module.scss';

export default function Order() {
  const navigator = useNavigate();
  const location = useLocation();
  const params = useParams();
  const {Echo} = useNative();
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const [name, setName] = useState('');
  const [searchParams] = useSearchParams();
  const [phoneNumber, setPhoneNumber] = useState('010');
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
  const [isOpenCouponSelector, setIsOpenCouponSelector] = useState(false);
  const [isShowQRCode, setIsShowQRCode] = useRecoilState(IsOpenQRCodePopup);
  const [isBrandingMessage, setIsBrandingMessage] = useRecoilState(
    IsBrandingMessageOnInstall,
  );
  const [isOpenAddressSearch, setIsOpenAddressSearch] = useState(false);
  const [magazineItem, setMagazineItem] = useState<MagazineItem>();
  const [couponTxt, setCouponTxt] = useState('');
  const [couponId, setCouponId] = useState(-1);
  const [postCode, setPostCode] = useState('');
  const [requestField, setRequestField] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [userOption, setUserOption] = useState('');
  const sender = useLogger();
  const metaInfo = useMemo(() => {
    const title = magazineItem?.title || '';
    const options = [
      location.state?.selectedDate.key || '',
      location.state?.selectedTime.key || '',
      `수량 ${location.state?.amount || 0}개`,
    ];
    const price = location.state?.price || 0;
    console.log({
      title,
      options,
      price: price * (location.state?.amount || 0),
      img: (magazineItem as MagazineItem)?.imageUrl,
    });
    return {
      title,
      options,
      price: price * (location.state?.amount || 0),
      img: (magazineItem as MagazineItem)?.imageUrl,
    };
  }, [location, magazineItem]);
  const isCouponAvailable = useMemo(() => {
    if (magazineItem) {
      return magazineItem.popupInfo?.couponAvailable;
    } else {
      return false;
    }
  }, [magazineItem]);
  const isPayDisabled = useMemo(() => {
    const isDelivery = magazineItem?.popupInfo?.type === 'delivery';
    if (isDelivery) {
      return (
        !name ||
        phoneNumber.length < 11 ||
        address.length === 0 ||
        detailAddress.length === 0 ||
        (magazineItem?.popupInfo?.requestRequired && requestField.length === 0)
      );
    } else {
      return (
        !name ||
        phoneNumber.length < 11 ||
        (magazineItem?.popupInfo?.requestRequired && requestField.length === 0)
      );
    }
  }, [name, phoneNumber, magazineItem, address, detailAddress, requestField]);
  const availableCouponCnt = useMemo(() => {
    let _c = coupons.filter(
      c => !c.magazineId || c.magazineId === location.state?.magazine.id,
    );
    _c = _c.filter(c => {
      return (
        Number(c.minAmount) <=
        Number(location.state?.price || '0') *
          Number(location.state?.amount || '0')
      );
    });
    _c = _c.filter(c => {
      return dayjs().diff(c.expireDate) < 0;
    });
    return _c.length;
  }, [coupons]);
  const enterNumber = ($event: React.ChangeEvent<HTMLInputElement>) => {
    setName($event.currentTarget.value);
  };

  const moveToAppScheme = () => {
    setTimeout(() => {
      if (isAndroid()) {
        window.location.href = 'market://launch?id=com.knewnew.app';
      } else if (isIos()) {
        window.open(
          'https://apps.apple.com/kr/app/knewnew-%EB%89%B4%EB%89%B4-f-b-%EB%B8%8C%EB%9E%9C%EB%93%9C-%ED%81%90%EB%A0%88%EC%9D%B4%EC%85%98/id6447512721',
          '_blank',
        );
      }
    }, 1000);
    setTimeout(() => {
      window.location.href = 'knewnew://home';
    }, 0);
  };

  const enterPhoneNumber = ($event: React.ChangeEvent<HTMLInputElement>) => {
    const v = $event.currentTarget.value;
    if (v.length <= 3) {
      setPhoneNumber('010');
    } else {
      setPhoneNumber(v.replace(/[^0-9]/g, ''));
    }
    const isValidPhoneNumber =
      v.startsWith('01') && /(\d{3})(\d{4})(\d{4})/.test(v);
    setIsPhoneNumberError(!isValidPhoneNumber);
  };
  const applyCoupon = (id: number, txt: string) => {
    setCouponId(id);
    setCouponTxt(txt);
  };
  const closeCouponModal = () => {
    navigator(location.pathname, {
      state: {
        ...location.state,
      },
    });
  };
  const callToFree = () => {
    const _title = metaInfo.title;
    const _price = metaInfo.price - discountPrice;
    const _optionId = location.state?.option;
    const orderId = generateRandomString();
    navigator(
      `/order/process?orderId=${orderId}&orderName=${_title}&option=${_optionId}&couponId=${couponId}&discountPrice=${discountPrice}`,
      {
        state: {
          magazine: magazineItem as MagazineItem,
          options: [
            location.state?.selectedDate.key || '',
            location.state?.selectedTime.key || '',
            `수량 ${location.state?.amount || 0}개`,
          ],
          price: _price,
          name,
          phoneNumber,
          couponId,
          discountPrice,
          postCode,
          address,
          detailAddress,
          userOption,
          amount: location.state?.amount,
          optionId: _optionId,
          productPrice: metaInfo.price,
          deliveryFee: magazineItem?.popupInfo?.deliveryFee || 0,
          orderRequest: requestField,
        },
      },
    );
  };
  const callTossPay = async () => {
    const _title = metaInfo.title;
    const _price = metaInfo.price - discountPrice;
    const _optionId = location.state?.option;
    const clientKey = CryptoJS.SHA1(`${userInfo.email}${userInfo.id}`, {
      asString: true,
    });
    const clientKey_res = CryptoJS.enc.Hex.stringify(clientKey);
    const orderId = generateRandomString();

    sender({
      _msg: 'buy_click3',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });

    if (_price === 0) {
      return callToFree();
    }
    if (Capacitor.getPlatform() !== 'web') {
      const successUrl = `knewnew://order/${
        (magazineItem as MagazineItem).id
      }/success`;
      const failUrl = 'knewnew://order/fail';
      const res: any = await Echo.echo({
        value: `tosspay*${orderId}*${_title.slice(
          0,
          60,
        )}*${successUrl}*${failUrl}*${
          _price + (magazineItem?.popupInfo?.deliveryFee || 0)
        }*${(magazineItem as MagazineItem).id}*${
          process.env.REACT_APP_TOSS_CLIENT_KEY
        }*${clientKey}`,
      });
      if (res.status === 'success') {
        navigator(
          `/order/process?${res.param}&orderId=${orderId}&orderName=${_title}&option=${_optionId}&couponId=${couponId}&discountPrice=${discountPrice}`,
          {
            state: {
              magazine: magazineItem as MagazineItem,
              options: [
                location.state?.selectedDate.key || '',
                location.state?.selectedTime.key || '',
                `수량 ${location.state?.amount || 0}개`,
              ],
              price: _price + (magazineItem?.popupInfo?.deliveryFee || 0),
              name,
              phoneNumber,
              couponId,
              discountPrice,
              postCode,
              address,
              detailAddress,
              userOption,
              amount: location.state?.amount,
              optionId: _optionId,
              productPrice: metaInfo.price,
              deliveryFee: magazineItem?.popupInfo?.deliveryFee || 0,
              orderRequest: requestField,
            },
          },
        );
      } else {
        navigator('/order/fail');
      }
    } else {
      console.log(`_optionId: ${_optionId}`);
      console.log(location.state?.selectedDate);
      console.log(location.state?.selectedTime);
      navigator(`/order/detail/payments/${(magazineItem as MagazineItem).id}`, {
        state: {
          pk: process.env.REACT_APP_TOSS_CLIENT_KEY,
          ck: clientKey_res,
          orderId,
          title: _title.slice(0, 60),
          price: _price + (magazineItem?.popupInfo?.deliveryFee || 0),
          magazine: magazineItem as MagazineItem,
          magazineId: (magazineItem as MagazineItem).id,
          selectedDate: location.state?.selectedDate,
          selectedTime: location.state?.selectedTime,
          name,
          phoneNumber,
          postCode,
          address,
          detailAddress,
          userOption,
          amount: location.state?.amount,
          optionId: _optionId,
          couponId: couponId,
          discountPrice: discountPrice,
          productPrice: metaInfo.price,
          deliveryFee: magazineItem?.popupInfo?.deliveryFee || 0,
          orderRequest: requestField,
        },
      });
    }
  };

  const getCouponData = async () => {
    const res = await getCouponList(accessToken, 0);
    setCoupons(res.data);
  };

  const getMagazineInfo = async () => {
    const id = params.id;
    getCouponData();
    const info: MagazineItem = await getContentsDetail(accessToken, Number(id));
    return info;
  };

  const discountPrice = useMemo(() => {
    if (couponId === -1) {
      return 0;
    }
    const item = coupons.find(coupon => coupon.id === couponId);

    if (item) {
      let price = 0;
      if (Number(item.discountAmount) === 0) {
        price =
          Number(item.maxDiscount) <
          metaInfo.price * (Number(item.discountRatio) / 100)
            ? Number(item.maxDiscount)
            : Math.floor(metaInfo.price * (Number(item.discountRatio) / 100));
      } else {
        price =
          Number(item.discountAmount) > metaInfo.price
            ? metaInfo.price
            : Number(item.discountAmount);
      }
      return price;
    } else {
      return 0;
    }
  }, [couponId, coupons]);

  const disabledBtn = useMemo(() => {
    if (magazineItem?.popupInfo?.type === 'delivery') {
      return (
        !name ||
        phoneNumber.length < 11 ||
        !address ||
        !detailAddress ||
        (magazineItem?.popupInfo?.requestRequired && requestField.length === 0)
      );
    }
    return (
      !name ||
      phoneNumber.length < 11 ||
      (magazineItem?.popupInfo?.requestRequired && requestField.length === 0)
    );
  }, [magazineItem, name, phoneNumber, address, detailAddress, requestField]);

  useEffect(() => {
    if (phoneNumber.length === 11) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    } else if (phoneNumber.length === 13) {
      const number = phoneNumber
        .replace(/-/g, '')
        .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      setPhoneNumber(number);
    } else if (phoneNumber.length < 7) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{4})/, '$1-$2'));
    }
  }, [phoneNumber]);

  useEffect(() => {
    const isCouponOpen = searchParams.get('coupon');
    if (isCouponOpen === 'true') {
      setIsOpenCouponSelector(true);
    } else {
      setIsOpenCouponSelector(false);
    }
  }, [searchParams]);

  useEffect(() => {
    setName(location.state?.name || '');
    setPhoneNumber(location.state?.phoneNumber || '010');
  }, []);

  return (
    <div className="main" style={{minHeight: '100vh', position: 'relative'}}>
      <ContentsHeader
        txt={'주문 / 결제'}
        isOption="none"
        isBottomLine=""
        back={() => {
          navigator(`/popup/${(magazineItem as MagazineItem).id}`, {
            state: {
              prev: 'home',
            },
          });
        }}
      />
      <Fetcher fetcher={getMagazineInfo} setter={setMagazineItem}>
        <div className={styles.product_info}>
          <h2>주문상품 정보</h2>
          <div className={styles.contents}>
            <div
              className={styles.img_wrapper}
              style={{
                background: `url("${metaInfo.img}") no-repeat center / cover`,
                borderRadius: '2px',
              }}></div>
            <div className={styles.txt_wrapper}>
              <h2>{metaInfo.title}</h2>
              <div className={styles.details}>
                {metaInfo.options.map(option => {
                  return (
                    <div key={option} className={styles.option}>
                      {option}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buyer_info}>
          <h2>구매자 정보</h2>
          <div className={styles.info}>
            <div className={styles.label}>이름</div>
            <input
              type="text"
              value={name}
              placeholder="이름을 입력해주세요"
              onChange={enterNumber}
            />
          </div>
          <div className={styles.info} style={{marginTop: '20px'}}>
            <div className={styles.label}>휴대폰 번호</div>
            <div>
              <input
                type="tel"
                className={`${isPhoneNumberError ? styles.error_border : ''}`}
                placeholder="숫자만 입력해주세요"
                maxLength={13}
                value={phoneNumber}
                onChange={enterPhoneNumber}
              />
            </div>
            <div>
              <div
                className={styles.error}
                style={{display: isPhoneNumberError ? 'block' : 'none'}}>
                {'휴대폰 번호가 올바르지 않습니다.'}
              </div>
            </div>
          </div>
          {magazineItem?.popupInfo?.requestRequired && (
            <div className={styles.info} style={{marginTop: '20px'}}>
              <div className={styles.label}>주문 요청사항</div>
              <div>
                <input
                  type={'text'}
                  placeholder="내용을 입력해주세요"
                  value={requestField}
                  onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
                    setRequestField($event.currentTarget.value)
                  }
                />
              </div>
              <div>
                <div className={styles.request_fields}>
                  <strong className={styles.tit}>꼭 확인해 주세요</strong>
                  <div
                    className={styles.request_notice}
                    style={{whiteSpace: 'pre-wrap'}}>
                    {magazineItem?.popupInfo?.requestTxt}
                  </div>
                </div>
              </div>
            </div>
          )}
          {magazineItem?.popupInfo?.type === 'delivery' && (
            <div className={styles.info} style={{marginTop: '20px'}}>
              <div className={styles.label}>주소</div>
              <div className={styles.addressInfo}>
                <input
                  type="text"
                  value={postCode}
                  placeholder={'우편번호'}
                  disabled={true}
                />
                <button
                  className={styles.addressSearch}
                  style={{width: '86px', height: '46px'}}
                  onClick={() => setIsOpenAddressSearch(true)}>
                  주소 검색
                </button>
              </div>
              <div className={styles.addressInfo2}>
                <input
                  type="text"
                  value={address}
                  placeholder={'기본주소'}
                  disabled={true}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder={'상세주소를 입력해주세요'}
                  onChange={($event: React.ChangeEvent<HTMLInputElement>) => {
                    setDetailAddress($event.currentTarget.value);
                  }}
                />
              </div>
              <div className={styles.label} style={{marginTop: '20px'}}>
                배송 요청사항 (선택)
              </div>
              <div>
                <input
                  type="text"
                  placeholder={'요청사항을 입력해주세요'}
                  onChange={($event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserOption($event.currentTarget.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.discountInfo}>
          <h2>할인 적용</h2>
          <div className={styles.info}>
            <div className={styles.label}>
              <span>쿠폰</span>
              <span
                className={`${styles.cnt} ${
                  !isCouponAvailable || availableCouponCnt === 0
                    ? styles.gray
                    : ''
                }`}>
                {isCouponAvailable
                  ? userInfo.isIdentified
                    ? `${availableCouponCnt}장`
                    : `${2}장`
                  : `0장`}
              </span>
            </div>
            <div className={styles.applyCouponArea}>
              <div className={styles.coupons}>
                <input
                  type="text"
                  className={!isCouponAvailable ? styles.notAvailable : ''}
                  value={couponTxt}
                  placeholder={`${
                    isCouponAvailable
                      ? '쿠폰을 선택해 주세요'
                      : '쿠폰 적용이 불가한 상품입니다.'
                  }`}
                  disabled
                />
                <button
                  disabled={!isCouponAvailable}
                  onClick={() =>
                    navigator(`${location.pathname}?coupon=true`, {
                      state: {
                        ...location.state,
                      },
                    })
                  }>
                  쿠폰 선택
                </button>
              </div>
              {Capacitor.getPlatform() === 'web' && !userInfo.isIdentified && (
                <div
                  className={styles.notIdentified}
                  onClick={() => {
                    if (isMobile()) {
                      moveToAppScheme();
                    } else {
                      setIsBrandingMessage(true);
                      setIsShowQRCode(true);
                    }
                  }}>
                  <span className={styles.appTxt}>
                    지금 APP에서 발급 가능한 쿠폰 2장 ✌️
                  </span>
                  <span className={styles.arrow}></span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.buy_info}>
          <div className={styles.price_info}>
            <h2>총 결제 금액</h2>
            <h2 className={styles.price}>{`${addComma(
              metaInfo.price -
                discountPrice +
                (magazineItem?.popupInfo?.deliveryFee || 0),
            )}원`}</h2>
          </div>
          <div className={styles.discountPrice}>
            <div>
              <span>상품 금액</span>
              <span>{`${addComma(metaInfo.price)}원`}</span>
            </div>
            <div style={{display: couponId > -1 ? 'flex' : 'none'}}>
              <span>쿠폰 할인</span>
              <span>{`-${addComma(discountPrice)}원`}</span>
            </div>
            {magazineItem?.popupInfo?.type === 'delivery' && (
              <div style={{display: 'flex'}}>
                <span>배송비</span>
                <span>{`${addComma(
                  Number(magazineItem?.popupInfo?.deliveryFee || 0),
                )}원`}</span>
              </div>
            )}
          </div>
          <div className={styles.agree_info}>
            <div>
              <div className={styles.agree_title}>
                개인정보 수집 및 제공에 관한 약관
              </div>
              <button
                onClick={() =>
                  navigator('/order/agreement', {
                    state: {
                      magazine: magazineItem as MagazineItem,
                      selectedDate: location.state?.selectedDate,
                      selectedTime: location.state?.selectedTime,
                      amount: location.state?.amount,
                      price: location.state?.price,
                      option: location.state?.option,
                      name,
                      phoneNumber,
                    },
                  })
                }>
                보기
              </button>
            </div>
            <div className={styles.message}>
              <div className={styles.title}>취소 및 환불 규정</div>
              <div>• 상세 페이지에 안내된 취소 규정을 따릅니다.</div>
            </div>
          </div>
          <div className={styles.process}>
            위 내용을 모두 확인하였으며,
            <br />
            개인정보 수집에 동의하고 결제를 진행합니다.
          </div>
          <button
            className={`${styles.process_btn} ${
              disabledBtn && styles.disabled
            }`}
            onClick={() => callTossPay()}
            disabled={isPayDisabled}>
            {addComma(
              metaInfo.price -
                discountPrice +
                (magazineItem?.popupInfo?.deliveryFee || 0),
            )}
            원 결제하기
          </button>
        </div>
      </Fetcher>

      <CouponSelector
        isOpen={isOpenCouponSelector}
        setIsOpen={() => closeCouponModal()}
        productPrice={metaInfo.price}
        applyCoupon={applyCoupon}
        applyedCoupon={couponId}
      />
      <AddressSearchModal
        isOpen={isOpenAddressSearch}
        setIsOpen={(isOpen: boolean) => setIsOpenAddressSearch(isOpen)}
        setupAddress={(address: string, postCode: number) => {
          setPostCode(`${postCode}`);
          setAddress(address);
        }}
      />
      {isMobile() ? (
        <Modal.Alert width={'315px'} height={'224px'} isOpen={isShowQRCode}>
          <div
            className={styles.contents}
            style={{padding: '30px 16px 10px 16px'}}>
            <div className={styles.iconArea}>
              <span className={styles.bookmarkIcon}></span>
            </div>
            <div className={styles.txtArea}>
              본인인증은 APP에서만
              <br />
              이용가능한 서비스입니다.
            </div>
            <div className={styles.buttonArea} style={{marginTop: '30px'}}>
              <Button.ModalButton
                width={'100%'}
                height={'50px'}
                txt={'앱 다운로드'}
                background={'#000'}
                color={'#fff'}
                onClick={() => {
                  moveToAppScheme();
                  setIsShowQRCode(false);
                }}
              />
              <Button.ModalButton
                width={'100%'}
                height={'50px'}
                txt={'닫기'}
                background={'#fff'}
                color={'#9F9CA3'}
                onClick={() => setIsShowQRCode(false)}
              />
            </div>
          </div>
        </Modal.Alert>
      ) : (
        <QRCodePopup isBranding={isBrandingMessage} />
      )}
    </div>
  );
}
