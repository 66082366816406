import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as NoSearchResult} from '@/assets/icons/no-search-result-icon.svg';
import {openBrowserForLink} from '@/utils';

import styles from '../styles.module.scss';

export default function NoAddressList() {
  const navigator = useNavigate();
  return (
    <div className={styles.noAddressList}>
      <NoSearchResult width={60} height={60} />
      <strong>오픈 예정 지역</strong>
      <p>
        이 지역에서 추천하고 싶은 곳이 있다면
        <br />
        뉴뉴에 알려주세요
      </p>
      <button onClick={() => navigator('/recommend-brand')}>
        브랜드 추천하기
      </button>
      <button
        className={styles.registerBrand}
        onClick={() => openBrowserForLink('https://www.knewnewbiz.co.kr/')}>
        내 브랜드 등록 문의 <span className={styles.registerArrow}></span>
      </button>
    </div>
  );
}
