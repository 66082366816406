import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ImageRail from '@/components/imageRail';
import {CourseBrandItem} from '@/views/search/components/CourseList/CourseList';

import styles from './styles.module.scss';
interface SubCurationItemProps {
  id: number;
  title: string;
  description: string;
  brandList: CourseBrandItem[];
}

interface ImageRailItem {
  id?: number;
  parentId?: number;
  url: string;
  title: string;
  description: string;
  price?: number;
  discountNumber?: number;
  keyword?: string;
}
function SubCurationItem({
  id,
  title,
  description,
  brandList,
}: SubCurationItemProps) {
  const navigator = useNavigate();
  const [images, setImages] = useState<ImageRailItem[]>([]);
  useEffect(() => {
    setImages(
      brandList.map(o => {
        return {
          id: o.id,
          parentId: o.parentId,
          url: o.collectionImage || o.imageList[0],
          title: o.title,
          description: o.keyword,
        };
      }),
    );
  }, [brandList]);
  return (
    <div className={styles.subCurationItem}>
      <div onClick={() => navigator(`/curation/${id}`)}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      <ImageRail
        title=""
        images={images}
        imageMarginLeft={0}
        wrapperStyle={{marginTop: '12px', marginBottom: '10px'}}
        titleWrapperStyle={{marginBottom: '0px'}}
        itemTitleStyle={{fontSize: '16px', marginTop: '12px'}}
        itemDescStyle={{fontSize: '12px', marginTop: '2px'}}
        callbackItemClick={(item: any) => {
          navigator(`/brand/${item.parentId}/${item.id}`);
        }}
        subCuration
      />
    </div>
  );
}

interface Props {
  subCurationItems: any;
}

export default function SubCuration({subCurationItems}: Props) {
  return (
    <div className={styles.subCurationWrapper}>
      {subCurationItems?.map((item: any) => {
        return (
          <SubCurationItem
            key={item.id}
            id={item.id}
            title={item.title.replace(/\\n/g, '\n')}
            description={item.subtitle}
            brandList={item.brandList || []}
          />
        );
      })}
    </div>
  );
}
