import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';

import queryKeys from '@/hooks/queries/brands/queryKeys';
import {
  getBrandBenefits,
  getBrandDetails,
  getSqureBrandList,
} from '@/services/apis/brands';
import {
  getBanners,
  getCourseBanners,
  getCourseList,
  getCurationList,
  getSubCuration,
} from '@/services/brands';
import {CourseListItem} from '@/views/search/components/CourseList/CourseList';
import {CurationData} from '@/views/Curation/Curation';

interface SubCurationItem {
  id: number;
  image: string;
  title: string;
  link: string;
}

export function useGetBrandDetails(
  payload: {id: number; placeId: number},
  options?: UseQueryOptions<BrandListItemType, Error>,
): UseQueryResult<BrandListItemType, Error> {
  return useQuery<BrandListItemType, Error>({
    ...options,
    queryKey: queryKeys.getBrandDetail(payload),
    queryFn: async () => {
      const res = await getBrandDetails(payload.id);
      const parentId = res.id;
      const placeInfo = res.brands.find(b => b.id === payload.placeId);
      return {
        ...placeInfo,
        parentId,
        subtitle: res.subtitle,
        bookmarkCount: res.bookmarkCount,
        isBookmark: res.isBookmark,
        childPlaces: res.brands,
      } as BrandListItemType;
    },
  });
}

export function useGetBenefits(
  payload: BrandBenefitParams,
  options?: UseQueryOptions<CollectionListItem[], Error>,
): UseQueryResult<CollectionListItem[], Error> {
  return useQuery<CollectionListItem[], Error>({
    ...options,
    queryKey: queryKeys.getBenefits(payload),
    queryFn: async () => {
      const res = await getBrandBenefits(payload);
      return res.results as CollectionListItem[];
    },
  });
}

export function useGetBrandSquare(
  payload: SquareBrandsParam,
  options?: UseQueryOptions<BrandListItemType[], Error>,
): UseQueryResult<BrandListItemType[], Error> {
  return useQuery<BrandListItemType[], Error>({
    ...options,
    queryKey: queryKeys.getSqureBrandList(payload),
    queryFn: async () => {
      const res = await getSqureBrandList(payload);
      return res.results as BrandListItemType[];
    },
  });
}

export function useGetHomeBanners(
  options?: UseQueryOptions<BrandListItemType[], Error>,
): UseQueryResult<BrandListItemType[], Error> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery<any, Error>({
    ...options,
    queryKey: queryKeys.getHomeBanners(),
    queryFn: async () => {
      const res = await getBanners();
      return res.data;
    },
  });
}

export function useGetCourseBanners(
  options?: UseQueryOptions<BrandListItemType[], Error>,
): UseQueryResult<BrandListItemType[], Error> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery<any, Error>({
    ...options,
    queryKey: queryKeys.getHomeBanners(),
    queryFn: async () => {
      const res = await getCourseBanners();
      return res.data;
    },
  });
}

export function useGetSubCuration(
  options?: UseQueryOptions<SubCurationItem[], Error>,
): UseQueryResult<SubCurationItem[], Error> {
  return useQuery<SubCurationItem[], Error>({
    ...options,
    queryKey: queryKeys.getSubCuration(),
    queryFn: async () => {
      const res = await getSubCuration();
      return res.data;
    },
  });
}

export function useGetCourseList(
  options?: UseQueryOptions<CourseListItem[], Error>,
): UseQueryResult<CourseListItem[], Error> {
  return useQuery<CourseListItem[], Error>({
    ...options,
    queryKey: queryKeys.getCourseList(),
    queryFn: async () => {
      const res = await getCourseList();
      return res.data;
    },
  });
}

export function useGetCurationList(
  payload: {magazine_collection_id: number},
  options?: UseQueryOptions<CurationData, Error>,
): UseQueryResult<CurationData, Error> {
  return useQuery<CurationData, Error>({
    ...options,
    queryKey: queryKeys.getCurationList({
      magazine_collection_id: payload.magazine_collection_id,
    }),
    queryFn: async () => {
      const res = await getCurationList(payload.magazine_collection_id);
      return res.data;
    },
  });
}
