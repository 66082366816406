import React, {useRef, useEffect} from 'react';
import cc from 'classcat';

import useImageHeight from '@/hooks/useImageHeight';
import {addComma} from '@/utils';
import useImLogger from '@/hooks/useImLogger';

import styles from './styles.module.scss';

interface Props {
  wrapperStyle?: React.CSSProperties;
  title: string;
  titleWrapperStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  link?: string;
  totalCnt?: number;
  subtitle?: string;
  images: {
    id?: number;
    parentId?: number;
    url: string;
    title: string;
    description: string;
    price?: number;
    discountNumber?: number;
    keyword?: string;
  }[];
  noFirstMargin?: boolean;
  imageMarginLeft?: number;
  disableDescription?: boolean;
  withTwoLineTitle?: boolean;
  itemTitleStyle?: React.CSSProperties;
  itemDescStyle?: React.CSSProperties;
  callbackItemClick?: <T>(item: T) => void;
  callbackMoreIcon?: () => void;
  isTogether?: boolean;
  noMargin?: boolean;
  subCuration?: boolean;
}

export default function ImageRail({
  wrapperStyle,
  title,
  subtitle,
  titleWrapperStyle,
  titleStyle,
  subtitleStyle,
  link,
  images,
  totalCnt,
  disableDescription,
  withTwoLineTitle,
  imageMarginLeft,
  noFirstMargin,
  itemTitleStyle,
  itemDescStyle,
  subCuration,
  callbackItemClick,
  callbackMoreIcon,
}: Props) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const {height: imgHeight, imageRef: imgRef, setHeight} = useImageHeight();
  const itemRefs = useRef<HTMLDivElement[]>([]);
  const sender = useImLogger();
  const observerRef = useRef<IntersectionObserver | null>(null);
  const subCurationStyle: React.CSSProperties = subCuration
    ? {
        minWidth: '160px',
        width: '160px',
        borderRadius: '0',
      }
    : {};

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const itemId = entry.target.getAttribute('data-id');
            const itemTitle = entry.target.getAttribute('data-title');
            const itemData = entry.target.getAttribute('data-item');
            const item = itemData ? JSON.parse(itemData) : null;

            //console.log('하위 요소 표시됨:', item);
            sender({
              _msg: itemTitle || '',
              _screen: window.location.pathname,
              _keyword: itemId?.toString(),
              _data: {to: item},
            });
            observerRef.current?.unobserve(entry.target); // 중복 호출 방지
          }
        });
      },
      {
        root: null, // 뷰포트를 기준으로 관찰
        threshold: 0.5,
      },
    );

    const observer = observerRef.current;

    itemRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, [images]);

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      <div className={styles.titleWrapper} style={titleWrapperStyle}>
        <div className={styles.title} style={titleStyle}>
          <h3>{title}</h3>
          {subtitle && (
            <span className={styles.subtitle} style={subtitleStyle}>
              {subtitle}
            </span>
          )}
        </div>
        {!!totalCnt && totalCnt > 5 && (
          <div
            className={styles.more}
            onClick={() => (callbackMoreIcon ? callbackMoreIcon() : '')}
            style={{display: link ? 'block' : 'none', cursor: 'pointer'}}>
            <span>더보기</span>
          </div>
        )}
      </div>
      <div className={styles.carousel}>
        <div className={styles.images} ref={carouselRef} id={'img'}>
          {images.length > 0 &&
            images.map((o, i) => {
              return (
                <div
                  ref={el => {
                    itemRefs.current[i] = el!;
                  }}
                  data-id={o.id}
                  data-title={o.title}
                  data-item={JSON.stringify(o)} // data-item 속성에 JSON 문자열로 item 저장
                  className={styles.brandItem}
                  key={`${o.url}_${i}`}
                  onClick={() =>
                    callbackItemClick ? callbackItemClick(o) : ''
                  }
                  style={{
                    marginLeft:
                      noFirstMargin && i === 0
                        ? 0
                        : i !== 0
                        ? imageMarginLeft !== undefined
                          ? imageMarginLeft + 'px'
                          : '20px'
                        : '20px',
                    ...subCurationStyle,
                  }}>
                  <img
                    ref={imgRef}
                    src={o.url}
                    alt=""
                    style={{
                      height: `${imgHeight}px`,
                      ...subCurationStyle,
                    }}
                    onLoad={() => {
                      if (imgRef.current) {
                        setHeight(imgRef.current.getBoundingClientRect().width);
                      }
                    }}
                  />
                  <div
                    className={cc([
                      styles.itemTitle,
                      withTwoLineTitle && styles.twoLines,
                    ])}
                    style={itemTitleStyle}>
                    {o.title}
                  </div>
                  {!disableDescription && (
                    <p className={styles.itemDesc} style={itemDescStyle}>
                      {o.description.replace('\\n', ' ')}
                    </p>
                  )}
                  {o?.price && (
                    <p className={styles.itemPrice}>
                      {!!o?.discountNumber && <span>{o?.discountNumber}%</span>}
                      {addComma(o?.price)}
                    </p>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
