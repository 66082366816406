import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {AxiosError} from 'axios';
import dayjs from 'dayjs';

export const switchNewLineTxt = (txt: string) => {
  if (typeof txt !== 'string') {
    return txt;
  }
  return txt.replace(/\n|\\n/g, '<br />');
};
export const makeShortContent = (txt: string) => {
  const seperated = txt.split('\n\n');
  return seperated.slice(0, 3).join('<br />');
};
export const timeForToday = (value: string) => {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60,
  );
  if (betweenTime < 1) return '방금전';
  if (betweenTime < 60) {
    return `${betweenTime}분전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 7) {
    return `${betweenTimeDay}일전`;
  }
  if (betweenTimeDay >= 7 && betweenTimeDay <= 30) {
    return `${Math.floor(betweenTimeDay / 7)}주 전`;
  }

  return dayjs(value).format('YYYY.MM.DD');
};

export const isAndroid = () => {
  return navigator.userAgent.toLowerCase().indexOf('android') > -1;
};

export const isIos = () => {
  const ua = navigator.userAgent;
  return /iP(hone|od)/.test(ua);
};

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    ua,
  );
};

export const countdown = (time: number) => {
  let _time = time;
  const min = Math.floor(_time / 60);
  const sec = _time - 60 * min;
  let minStr = `${min}`;
  let secStr = `${sec}`;
  if (min.toString().length == 1) {
    minStr = `${min}`;
  }

  if (sec.toString().length == 1) {
    secStr = '0' + sec;
  }

  if (_time == 0) {
    //카운트다운 종료
    return {
      afterTime: _time,
      txt: `0:00`,
    };
  }
  return {
    afterTime: _time--,
    txt: `${minStr}:${secStr}`,
  };
};

export const dummyCommunity: Community = {
  id: -1,
  author: {
    id: 1340,
    tags: {
      foodStyle: [],
      occupation: [],
      household: [],
    },
    nickname: '',
    profileImage: '',
    badge: null,
    followerCount: 0,
  },
  title: 'TEST community',
  product: null,
  market: null,
  content: 'TEST strings',
  images: [],
  satisfaction: 'good',
  category: '',
  brands: {
    id: 77,
    title: '리홀뮤직갤러리',
    info: null,
    type: 'place',
    thumbnailUrl:
      'https://knewnnew-dev-s3.s3.ap-northeast-2.amazonaws.com/delicacies/brand/%EB%A6%AC%ED%99%80%EB%AE%A4%EC%A7%81%EA%B0%A4%EB%9F%AC%EB%A6%AC/Untitled+13.jpeg',
    address: '서울 성북구 성북로31길 9 3층',
    openingHours: '화~일 11:30 - 20:50\\n월요일 휴무',
    productName: '',
    whereToBuy: '',
    state: 'verified',
    categoryList: [],
    brandLink: 'https://www.instagram.com/rheehallmusicgallery/tagged/',
    brandImages: [],
    hashtags: [],
    points: [
      '만 원으로 음료 한 잔과 신청곡 감상을',
      '일리(illy) 원두를 사용하는 커피 맛집',
      '1930년대 빈티지 스피커 사운드',
    ],
  },
  address1: '서울',
  address2: '강남구',
  tags: {},
  bookmarkContent: 0,
  commentCount: 3,
  likeCount: 4,
  viewCount: 78,
  created: '2023-03-02T15:51:42.429923+09:00',
  isActive: true,
  isBookmark: false,
  isEdit: false,
  isLike: false,
};

export const loadImg = (imgPath: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(null);
    };
    img.src = imgPath;
  });
};

export const loadBannerImages = (
  imgs: string[],
): Promise<HTMLImageElement[]> => {
  return new Promise((resolve, reject) => {
    Promise.all(imgs.map(img => loadImg(img)))
      .then(values => {
        resolve(values);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      });
  });
};

export const providerTypes = {
  email: '이메일',
  kakao: '카카오',
  google: '구글',
  naver: '네이버',
  apple: '애플',
  '': '',
};
export const loadImage = (imgPath: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(null);
    };
    img.src = imgPath;
  });
};

export const addComma = (num: number) => {
  if (typeof num !== 'number') {
    return num;
  }
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
};

export const errorInterceptor = async (error: AxiosError): Promise<void> => {
  if (!error?.isAxiosError) {
    throw error;
  }
  if (error.response?.status === 401) {
    console.log('in error respnse');
    if (location.pathname.indexOf('/login') === -1) {
      location.href = '/login';
    }
  } else {
    throw error;
  }
};
export const generateRandomString = () => {
  return window.btoa(`${Math.random()}`).slice(0, 20);
};

export const openBrowser = async () => {
  if (Capacitor.getPlatform() === 'web') {
    window.open('http://pf.kakao.com/_qmUqG/chat', '_blank');
  } else {
    await Browser.open({
      url: 'http://pf.kakao.com/_qmUqG/chat',
    });
  }
};

export const openBrowserForLink = async (link: string) => {
  if (Capacitor.getPlatform() === 'web') {
    window.open(link, '_blank');
  } else {
    await Browser.open({
      url: link,
    });
  }
};

export const openNaverMap = (
  startPos: number[],
  arrivePos: number[],
  name: string,
) => {
  if (Capacitor.getPlatform() === 'web') {
    const domain = `http://${
      isMobile() ? 'm.naver.com' : 'map.naver.com/p/directions/'
    }`;
    if (isMobile()) {
      window.open(
        `${domain}/route.nhn?sx=${startPos[0]}&sy=${
          startPos[1]
        }&sname=${'현재위치'}&ex=${arrivePos[0]}&ey=${
          arrivePos[1]
        }&ename=${name}&menu=route&pathType=1`,
        '_blank',
      );
    } else {
      window.open(
        `http://map.naver.com/index.nhn
        ?slng=126.8966655
        &slat=37.4830969
        &stext=출발지이름
        &elng=127.0276368
        &elat=37.4979502
        &etext=도착지이름
        &menu=route
        &pathType=1`,
        '_blank',
      );
    }
  } else {
    // TODO: 앱일경우
  }
};

export const dummyCollection: CollectionListItem = {
  id: -1,
  title: '',
  subtitle: '',
  status: '',
  info: '',
  badgeTxt: '',
  price: '',
  type: 'foodlife',
  category: 'brands',
  target: 'place',
  imageUrl: '',
  collectionImageUrl: '',
  link: '',
  priority: -1,
  created: '',
  isBookmark: false,
};

export const findMatchingId = (address: string, addressList: Address[]) => {
  if (!address) {
    return {guId: -1, dongId: -1};
  }
  // Step 1: 구와 동 추출
  const gu = address.match(/([가-힣]+구)/)?.[1]; // "강남구"
  const dong = address.match(/([가-힣0-9]+동)/)?.[1]; // "삼성2동"

  let guId = null;
  let dongId = null;

  // Step 2: addressList에서 구와 매칭된 ID 찾기
  const matchingGu = addressList.find(item => item.address === gu);
  if (matchingGu) {
    guId = matchingGu.id;

    // Step 3: 구의 child에서 동과 매칭된 ID 찾기 (두 글자라도 포함되어 있는지 확인)
    const matchingDong = matchingGu.child?.find(child => {
      const childDong = child.address.replace(/\d/g, ''); // 숫자 제거
      const inputDong = dong?.replace(/\d/g, ''); // 숫자 제거
      return inputDong?.slice(0, 2) === childDong.slice(0, 2); // 두 글자 비교
    });

    if (matchingDong) {
      dongId = matchingDong.id;
    }
  }

  // Step 4: 결과 반환
  return {guId, dongId};
};

export const findGuAndDong = (
  data: Address[],
  dongName: string,
): {gu: Address | undefined; dong: Address | undefined} => {
  for (const location of data) {
    const child = location.child?.find(child => child.address === dongName);
    if (child) {
      return {
        gu: location,
        dong: child,
      };
    }
  }
  return {gu: undefined, dong: undefined};
};

export function extractRegionAndNeighborhood(jibunAddress: string) {
  const parts = jibunAddress.split(' '); // 공백으로 나누고 양쪽 공백 제거
  return {
    region: parts.length > 2 ? parts[parts.length - 2] : null, // 두 번째 값: '구'
    neighborhood: parts.length > 2 ? parts[parts.length - 1] : null, // 마지막 값: '동'
  };
}
